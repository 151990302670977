*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: cera_pro, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

html {
  font-family: adobe-caslon-pro, Georgia, Times New Roman, times, serif;
}

html h1, html h2, html h3, html h4, html h5, html h6, html p, html blockquote, html ul, html ol {
  line-height: 2em;
}

a:not(.btn), button {
  cursor: pointer;
}

a {
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
}

@media (min-width: 768px) {
  a {
    white-space: nowrap;
  }
}

article a:not(.btn), div a:not(.btn), p a:not(.btn), section a:not(.btn), main a:not(.btn) {
  text-decoration: underline;
}

p {
  margin-bottom: 2rem;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

.btn {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(215 218 221 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  text-align: center;
  letter-spacing: -.025em;
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
  border-radius: .375rem;
  padding: .75rem 2.5rem;
  font-family: cera_pro, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 16px;
  line-height: 20px;
  transition: all .4s cubic-bezier(.67, .075, .34, 1.65);
  box-shadow: 0 3px 15px #15406a26;
}

.btn:hover {
  transform: scale(1.05);
}

.btn.font-serif {
  padding-bottom: .5rem;
}

.btn.btn-sm {
  padding: 1rem 2rem;
  font-size: 16px;
  line-height: 20px;
}

.btn.btn-lg {
  padding: 1rem 2rem;
  font-size: 28px;
  line-height: 32px;
}

.btn.btn-lg.btn-unicorn {
  box-shadow: 0 3px 30px #15406a80;
}

.btn.btn-xl {
  padding: 2rem 4rem;
  font-size: 36px;
  line-height: 36px;
}

.btn.btn-xl.btn-unicorn {
  --tw-shadow: 0px 3px 30px #15406a59;
  --tw-shadow-colored: 0px 3px 30px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.btn.btn-wide {
  padding-left: 5rem;
  padding-right: 5rem;
}

.btn.btn-xwide {
  padding-left: 10rem;
  padding-right: 10rem;
}

.btn.btn-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(21 64 106 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-width: 0;
}

.btn.btn-unicorn {
  --tw-bg-opacity: 1;
  background-color: rgb(231 235 240 / var(--tw-bg-opacity));
  border-width: 0;
  box-shadow: 0 3px 15px #15406a80;
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.z-10 {
  z-index: 10;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-auto {
  height: auto;
}

.w-48 {
  width: 12rem;
}

.w-full {
  width: 100%;
}

.w-64 {
  width: 16rem;
}

.grow {
  flex-grow: 1;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.self-center {
  align-self: center;
}

.rounded-full {
  border-radius: 9999px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-tuftsy-silver {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(181 214 232 / var(--tw-border-opacity));
}

.bg-blue-lightest {
  --tw-bg-opacity: 1;
  background-color: rgb(247 249 250 / var(--tw-bg-opacity));
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pt-14 {
  padding-top: 3.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-sans {
  font-family: cera_pro, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.font-handwriting {
  font-family: Qwitcher Grypen, cursive;
}

.text-base {
  font-size: 22px;
  line-height: 30px;
}

.text-lg {
  font-size: 28px;
  line-height: 32px;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-xl {
  font-size: 36px;
  line-height: 36px;
}

.font-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-blue {
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
}

.opacity-100 {
  opacity: 1;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@media (min-width: 768px) {
  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .md\:ml-16 {
    margin-left: 4rem;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:ml-12 {
    margin-left: 3rem;
  }

  .md\:mr-10 {
    margin-right: 2.5rem;
  }

  .md\:ml-8 {
    margin-left: 2rem;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:w-3\/5 {
    width: 60%;
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:text-left {
    text-align: left;
  }
}

form {
  width: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

form .warning {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

form input[type="text"], form input[type="email"], form textarea {
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
  border-width: 1px;
  border-radius: .375rem;
  margin-bottom: 2rem;
  padding: 1rem 1rem .5rem;
  font-size: 16px;
  line-height: 20px;
  display: block;
}

form input[type="text"].invalid, form input[type="email"].invalid, form textarea.invalid {
  --tw-border-opacity: 1 !important;
  border-color: rgb(239 68 68 / var(--tw-border-opacity)) !important;
}

form:after {
  pointer-events: none;
  z-index: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  content: "";
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

form.bg-dark input, form.bg-dark textarea {
  --tw-shadow: 0px 3px 30px #00000059;
  --tw-shadow-colored: 0px 3px 30px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

form input, form select, form textarea, form button {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

form .spinner {
  pointer-events: none;
  z-index: 10;
  opacity: 0;
  margin-top: -1.5rem;
  margin-left: -1.5rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 50%;
  left: 50%;
}

form .submitted-message {
  pointer-events: none;
  z-index: 10;
  width: 100%;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: adobe-caslon-pro;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: absolute;
  font-size: 28px !important;
  line-height: 32px !important;
}

form .errors {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

form.submitting, form.submitted {
  pointer-events: none;
}

form.submitting input, form.submitting select, form.submitting textarea, form.submitting button, form.submitting ul, form.submitted input, form.submitted select, form.submitted textarea, form.submitted button, form.submitted ul {
  opacity: .1;
}

form.submitting:after, form.submitted:after, form.submitting .spinner, form.submitted .submitted-message {
  opacity: 1;
}

.footer-form form .warning, .footer-form form .errors {
  --tw-text-opacity: 1;
  color: rgb(254 202 202 / var(--tw-text-opacity));
}

.footer-form form .spinner {
  background: radial-gradient(farthest-side, #fff 94%, #0000) top / 9px 9px no-repeat, conic-gradient(#0000 30%, #fff);
}

.footer-form form .submitted-message {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.footer-form form input[type="text"].invalid, .footer-form form input[type="email"].invalid, .footer-form form textarea.invalid {
  --tw-border-opacity: 1 !important;
  border-color: rgb(252 165 165 / var(--tw-border-opacity)) !important;
}

ol li {
  margin: 1.5rem;
  position: relative;
}

ol li:before, ol li:after, ol li span:before, ol li span:after {
  width: 1.5rem;
  text-align: right;
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
  height: 2.25rem;
  flex-direction: column;
  justify-content: center;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  position: absolute;
  left: 0;
}

ol li:before {
  margin-left: -2rem;
  top: 0;
}

ol li span:before {
  margin-left: -2.5rem;
  top: 0;
  left: -.05rem;
}

ol li:after {
  margin-left: -3rem;
  top: 0;
  left: -.15rem;
}

ol li span:after {
  margin-left: -2.5rem;
  top: 0;
  left: -.05rem;
}

ol li:nth-child(1):before, ol li:nth-child(2):before, ol li:nth-child(3):before, ol li:nth-child(4):before, ol li:nth-child(2) span:before, ol li:nth-child(3) span:before, ol li:nth-child(4) span:before, ol li:nth-child(3):after, ol li:nth-child(4):after, ol li:nth-child(4) span:after {
  content: "•";
}

ol li:nth-child(4):before, ol li:nth-child(4) span:before {
  top: -.25rem;
}

ol li:nth-child(4):after {
  margin-left: -2rem;
  left: 0;
}

ol li:nth-child(4):after, ol li:nth-child(4) span:after {
  top: .25rem;
}

ul.bullets li {
  margin-bottom: 1rem;
  margin-left: 1.5rem;
  line-height: 1.25;
  position: relative;
}

ul.bullets li:before {
  height: 100%;
  width: 1.5rem;
  text-align: right;
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
  content: "•";
  flex-direction: column;
  justify-content: center;
  margin-left: -2.5rem;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

body.loaded.nav-loaded nav.main-menu {
  transition-property: box-shadow, background, filter;
  transition-duration: .3s;
}

nav.main-menu {
  z-index: 50;
  width: 100%;
  --tw-drop-shadow: drop-shadow(0 0 #0000);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  background-color: #0000;
  align-items: center;
  font-size: 22px;
  line-height: 30px;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: fixed;
  top: 0;
}

@media (min-width: 768px) {
  nav.main-menu {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    justify-content: center;
    display: grid;
  }
}

nav.main-menu.scrolled {
  --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  background-color: #fffffff2;
}

nav.main-menu .logo-badge {
  padding: 2rem;
  display: block;
}

nav.main-menu .logo-badge img {
  height: 3rem;
  width: auto;
}

nav.main-menu.open ul {
  height: 20rem;
}

nav.main-menu .hamburger {
  display: block;
}

@media (min-width: 768px) {
  nav.main-menu .hamburger {
    display: none;
  }
}

nav.main-menu ul {
  height: 0;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  justify-content: center;
  transition-duration: .3s;
  position: absolute;
  top: 6rem;
  left: 0;
  overflow: hidden;
}

@media (min-width: 768px) {
  nav.main-menu ul {
    height: auto;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    background-color: #0000;
    display: flex;
    position: relative;
    top: 0;
  }
}

nav.main-menu ul {
  transition-property: height;
}

nav.main-menu ul li a {
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
  padding: 2rem 2rem 1.5rem;
  display: block;
  position: relative;
}

nav.main-menu ul li a:before {
  pointer-events: none;
  margin-left: -2.25rem;
  display: none;
  position: absolute;
}

@media (min-width: 768px) {
  nav.main-menu ul li a:before {
    display: inline-block;
  }
}

nav.main-menu ul li a:before {
  content: "•";
}

nav.main-menu ul li a:after {
  height: .125rem;
  --tw-bg-opacity: 1;
  background-color: rgb(21 64 106 / var(--tw-bg-opacity));
  content: " ";
  width: 0;
  width: -10rem;
  margin-left: 1.25rem;
  transition: all .4s cubic-bezier(.67, .075, .34, 1.65);
  display: block;
  position: absolute;
  bottom: 2.25rem;
  left: 50%;
}

nav.main-menu ul li a:hover:after, nav.main-menu ul li a.active:after {
  width: calc(100% - 4rem);
  left: .75rem;
}

nav.main-menu ul li:first-child a:before {
  content: "";
}

nav.main-menu .btn {
  margin-top: -.75rem;
  margin-right: 1.25rem;
  padding: .75rem 1.25rem;
  font-size: 16px;
  line-height: 20px;
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.z-10 {
  z-index: 10;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-auto {
  height: auto;
}

.w-48 {
  width: 12rem;
}

.w-full {
  width: 100%;
}

.w-64 {
  width: 16rem;
}

.grow {
  flex-grow: 1;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.self-center {
  align-self: center;
}

.rounded-full {
  border-radius: 9999px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-tuftsy-silver {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(181 214 232 / var(--tw-border-opacity));
}

.bg-blue-lightest {
  --tw-bg-opacity: 1;
  background-color: rgb(247 249 250 / var(--tw-bg-opacity));
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pt-14 {
  padding-top: 3.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-sans {
  font-family: cera_pro, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.font-handwriting {
  font-family: Qwitcher Grypen, cursive;
}

.text-base {
  font-size: 22px;
  line-height: 30px;
}

.text-lg {
  font-size: 28px;
  line-height: 32px;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-xl {
  font-size: 36px;
  line-height: 36px;
}

.font-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-blue {
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
}

.opacity-100 {
  opacity: 1;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@media (min-width: 768px) {
  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .md\:ml-16 {
    margin-left: 4rem;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:ml-12 {
    margin-left: 3rem;
  }

  .md\:mr-10 {
    margin-right: 2.5rem;
  }

  .md\:ml-8 {
    margin-left: 2rem;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:w-3\/5 {
    width: 60%;
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:text-left {
    text-align: left;
  }
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

.hero {
  min-height: min-content;
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #fff0;
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to);
  --tw-gradient-to: #edf3fc;
  --tw-shadow: 0px 20px 30px #15406a29;
  --tw-shadow-colored: 0px 20px 30px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-bottom-left-radius: 26px;
  border-bottom-right-radius: 26px;
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.hero .container {
  width: fit-content;
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
  flex-direction: column;
  justify-content: center;
  padding-top: 13rem;
  padding-bottom: 8rem;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  .hero .container {
    min-height: min-content;
  }
}

.hero .container h1, .hero .container h2 {
  text-align: left;
  text-transform: none;
  font-family: adobe-caslon-pro;
  font-size: 3rem;
  line-height: 1.5;
  display: inline-block;
}

.hero .container h1 {
  margin-bottom: 8rem;
}

.hero .container button {
  align-self: center;
  margin-top: 5rem;
  margin-bottom: -1.25rem;
  font-size: 22px;
  line-height: 30px;
}

.hero .container * {
  z-index: 10;
  position: relative;
}

.hero:after {
  pointer-events: none;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: -.025em;
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
  opacity: .03;
  z-index: 0;
  filter: blur(6px);
  font-family: cera_pro, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 400px;
  line-height: 1;
  display: block;
  position: absolute;
  bottom: -.2em;
  left: -.2em;
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.z-10 {
  z-index: 10;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-auto {
  height: auto;
}

.w-48 {
  width: 12rem;
}

.w-full {
  width: 100%;
}

.w-64 {
  width: 16rem;
}

.grow {
  flex-grow: 1;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.self-center {
  align-self: center;
}

.rounded-full {
  border-radius: 9999px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-tuftsy-silver {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(181 214 232 / var(--tw-border-opacity));
}

.bg-blue-lightest {
  --tw-bg-opacity: 1;
  background-color: rgb(247 249 250 / var(--tw-bg-opacity));
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pt-14 {
  padding-top: 3.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-sans {
  font-family: cera_pro, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.font-handwriting {
  font-family: Qwitcher Grypen, cursive;
}

.text-base {
  font-size: 22px;
  line-height: 30px;
}

.text-lg {
  font-size: 28px;
  line-height: 32px;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-xl {
  font-size: 36px;
  line-height: 36px;
}

.font-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-blue {
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
}

.opacity-100 {
  opacity: 1;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@media (min-width: 768px) {
  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .md\:ml-16 {
    margin-left: 4rem;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:ml-12 {
    margin-left: 3rem;
  }

  .md\:mr-10 {
    margin-right: 2.5rem;
  }

  .md\:ml-8 {
    margin-left: 2rem;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:w-3\/5 {
    width: 60%;
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:text-left {
    text-align: left;
  }
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

.well {
  z-index: 0;
  margin-top: -3rem;
  position: relative;
}

@media (min-width: 768px) {
  .well {
    padding: 8rem 2.5rem;
  }
}

.well {
  background-position: center;
  background-size: cover;
}

.well .container {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.well .container h1, .well .container h2, .well .container h3, .well .container h4 {
  max-width: 64rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
  --tw-shadow: 0px 3px 60px #15406a80;
  --tw-shadow-colored: 0px 3px 60px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 26px;
  margin-left: auto;
  margin-right: auto;
  padding: 3.5rem 1rem 3rem;
  font-size: 22px;
  line-height: 30px;
}

@media (min-width: 768px) {
  .well .container h1, .well .container h2, .well .container h3, .well .container h4 {
    padding-left: 4rem;
    padding-right: 4rem;
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

.well .container h1, .well .container h2, .well .container h3, .well .container h4 {
  line-height: 2;
}

.well + div, .well + article, .well + section {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0px -20px 30px #15406a59;
  --tw-shadow-colored: 0px -20px 30px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-top-left-radius: 26px;
  border-top-right-radius: 26px;
  margin-top: 3rem;
}

@media (min-width: 768px) {
  .well + div, .well + article, .well + section {
    margin-top: -3rem;
  }
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.z-10 {
  z-index: 10;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-auto {
  height: auto;
}

.w-48 {
  width: 12rem;
}

.w-full {
  width: 100%;
}

.w-64 {
  width: 16rem;
}

.grow {
  flex-grow: 1;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.self-center {
  align-self: center;
}

.rounded-full {
  border-radius: 9999px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-tuftsy-silver {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(181 214 232 / var(--tw-border-opacity));
}

.bg-blue-lightest {
  --tw-bg-opacity: 1;
  background-color: rgb(247 249 250 / var(--tw-bg-opacity));
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pt-14 {
  padding-top: 3.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-sans {
  font-family: cera_pro, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.font-handwriting {
  font-family: Qwitcher Grypen, cursive;
}

.text-base {
  font-size: 22px;
  line-height: 30px;
}

.text-lg {
  font-size: 28px;
  line-height: 32px;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-xl {
  font-size: 36px;
  line-height: 36px;
}

.font-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-blue {
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
}

.opacity-100 {
  opacity: 1;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@media (min-width: 768px) {
  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .md\:ml-16 {
    margin-left: 4rem;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:ml-12 {
    margin-left: 3rem;
  }

  .md\:mr-10 {
    margin-right: 2.5rem;
  }

  .md\:ml-8 {
    margin-left: 2rem;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:w-3\/5 {
    width: 60%;
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:text-left {
    text-align: left;
  }
}

.circle-item-list {
  margin-top: 2.5rem;
}

.circle-item-list > ul {
  display: grid;
}

.circle-item-list.cols-auto > ul {
  grid-auto-columns: auto;
  grid-auto-flow: column;
}

.circle-item-list.cols-max > ul {
  grid-auto-columns: max-content;
  grid-auto-flow: column;
}

.circle-item-list.cols-2 > ul {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 5rem;
}

.circle-item-list.cols-3 > ul {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1rem;
}

@media (min-width: 768px) {
  .circle-item-list.cols-3 > ul {
    gap: 5rem;
  }
}

.circle-item-list.cols-4 > ul {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 5rem;
}

.circle-item-list.cols-5 > ul {
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 5rem;
}

.circle-item-list li a {
  letter-spacing: -.025em;
  flex-direction: column;
  align-items: center;
  font-family: cera_pro, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 16px;
  line-height: 20px;
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
  display: flex;
  position: relative;
}

.circle-item-list strong {
  text-align: center;
  margin-bottom: .5rem;
  font-family: cera_pro, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 400;
  display: block;
}

.circle-item-list em {
  text-align: center;
  font-family: adobe-caslon-pro;
  font-size: 14px;
  line-height: 20px;
  display: block;
}

@media (min-width: 768px) {
  .circle-item-list em {
    font-size: 22px;
    line-height: 30px;
  }
}

.circle-item-list.fade-circled li a {
  margin-bottom: .5rem;
  display: block;
  position: relative;
}

.circle-item-list.fade-circled li a img {
  max-height: 15rem;
  border-radius: 9999px;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
}

.circle-item-list.fade-circled li a img.faded {
  display: none;
}

.circle-item-list.fade-circled li a img.full {
  opacity: .4;
  border-radius: 9999px;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
  top: 0;
  left: 0;
}

.circle-item-list.fade-circled li a strong {
  letter-spacing: -.025em;
  font-size: 22px;
  line-height: 30px;
}

.circle-item-list.blue-circled li a span {
  margin-bottom: .5rem;
  display: block;
  position: relative;
}

.circle-item-list.blue-circled li a span:before {
  height: 100%;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(21 64 106 / var(--tw-bg-opacity));
  content: "";
  border-radius: 9999px;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: absolute;
  top: 0;
}

.circle-item-list.blue-circled li a span img {
  border-radius: 9999px;
  display: block;
  position: relative;
}

.circle-item-list.blue-circled li a span img.faded {
  opacity: .8;
  mix-blend-mode: luminosity;
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.circle-item-list.blue-circled li a span img.full {
  height: 100%;
  width: 100%;
  opacity: 0;
  border-radius: 9999px;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.circle-item-list.fade-circled li a:hover:before, .circle-item-list.fade-circled li a.active:before, .circle-item-list.blue-circled li a:hover:before, .circle-item-list.blue-circled li a.active:before {
  opacity: 0;
}

.circle-item-list.fade-circled li a:hover img.full, .circle-item-list.fade-circled li a.active img.full, .circle-item-list.blue-circled li a:hover img.full, .circle-item-list.blue-circled li a.active img.full {
  opacity: 1;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

.modal-centered .modal-dialog {
  top: 50%;
  transform: translateY(-50%);
}

.modal-full .modal-dialog {
  width: 100vw;
  max-width: 100vw;
}

.modal-lg .modal-dialog {
  max-width: 1140px;
  width: 1140px;
}

.modal-xl .modal-dialog {
  max-width: 1380px;
  width: 1380px;
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.z-10 {
  z-index: 10;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-auto {
  height: auto;
}

.w-48 {
  width: 12rem;
}

.w-full {
  width: 100%;
}

.w-64 {
  width: 16rem;
}

.grow {
  flex-grow: 1;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.self-center {
  align-self: center;
}

.rounded-full {
  border-radius: 9999px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-tuftsy-silver {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(181 214 232 / var(--tw-border-opacity));
}

.bg-blue-lightest {
  --tw-bg-opacity: 1;
  background-color: rgb(247 249 250 / var(--tw-bg-opacity));
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pt-14 {
  padding-top: 3.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-sans {
  font-family: cera_pro, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.font-handwriting {
  font-family: Qwitcher Grypen, cursive;
}

.text-base {
  font-size: 22px;
  line-height: 30px;
}

.text-lg {
  font-size: 28px;
  line-height: 32px;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-xl {
  font-size: 36px;
  line-height: 36px;
}

.font-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-blue {
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
}

.opacity-100 {
  opacity: 1;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

body.modal-open {
  padding-right: 15px;
  overflow: hidden;
}

body.modal-open nav.main-menu {
  padding-right: 15px;
}

.modal-backdrop {
  height: 100vh;
  width: 100vw;
  opacity: 0;
  z-index: 1050;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.show {
  pointer-events: auto;
  opacity: .5;
}

.modal {
  pointer-events: none;
  height: 100vh;
  width: 100vw;
  opacity: 0;
  outline-offset: 2px;
  z-index: 1051;
  outline: 2px solid #0000;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.modal .modal-dialog {
  pointer-events: none;
  width: 100vw;
  border-radius: .5rem;
  position: relative;
  left: 50%;
  overflow-y: hidden;
}

@media (min-width: 768px) {
  .modal .modal-dialog {
    width: auto;
  }
}

.modal .modal-dialog {
  width: 600px;
  max-width: 100%;
  top: 3rem;
  bottom: 3rem;
  transform: translateX(-50%);
}

.modal .modal-dialog .modal-content {
  pointer-events: none;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  color: currentColor;
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-offset: 2px;
  background-clip: padding-box;
  border-style: none;
  outline: 2px solid #0000;
  flex-direction: column;
  padding: 1rem;
  display: flex;
  position: relative;
  overflow-y: auto;
}

@media (min-width: 768px) {
  .modal .modal-dialog .modal-content {
    padding: 3rem 4rem;
  }
}

.modal .modal-dialog .modal-content {
  max-height: calc(100vh - 6rem);
}

.modal .modal-dialog .modal-header h1, .modal .modal-dialog .modal-header h2, .modal .modal-dialog .modal-header h3, .modal .modal-dialog .modal-header h4, .modal .modal-dialog .modal-header h5 {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
  align-self: stretch;
  margin-bottom: 2rem;
  font-family: cera_pro, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 36px;
  font-weight: 500;
  line-height: 1.25;
}

.modal .modal-dialog .modal-header button[data-dismiss="modal"] {
  box-sizing: content-box;
  height: 2rem;
  width: 2rem;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  opacity: .3;
  border-style: none;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: .5rem;
  right: .5rem;
}

.modal .modal-dialog .modal-header button[data-dismiss="modal"]:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  opacity: .75;
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

.modal .modal-dialog .modal-header button[data-dismiss="modal"]:focus {
  opacity: 1;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.modal .modal-dialog .modal-header button[data-dismiss="modal"]:after {
  height: 2rem;
  content: "×";
  font-size: 3rem;
  line-height: 1;
  display: block;
}

.modal.show {
  opacity: 1;
  display: block;
}

.modal.show .modal-dialog, .modal.show .modal-dialog .modal-content {
  pointer-events: auto;
}

@media (min-width: 768px) {
  .md\:modal-lg .modal-dialog {
    max-width: 1140px;
    width: 1140px;
  }

  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .md\:ml-16 {
    margin-left: 4rem;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:ml-12 {
    margin-left: 3rem;
  }

  .md\:mr-10 {
    margin-right: 2.5rem;
  }

  .md\:ml-8 {
    margin-left: 2rem;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:w-3\/5 {
    width: 60%;
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:text-left {
    text-align: left;
  }
}

.fade {
  transition: opacity .15s ease-in-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    top: 100%;
  }

  to {
    opacity: 1;
    top: 0;
  }
}

@keyframes fadeInBlur {
  from {
    opacity: 0;
    filter: blur(20px);
  }

  to {
    opacity: 1;
    filter: blur();
  }
}

@keyframes fadeInUpSmall {
  from {
    opacity: 0;
    top: 10%;
  }

  to {
    opacity: 1;
    top: 0;
  }
}

.fadeUp {
  animation-name: fadeInUp;
  transition-duration: .25s !important;
  animation-duration: .25s !important;
  animation-iteration-count: 1 !important;
}

.fadeUpSmall {
  animation-name: fadeInUpSmall;
  transition-duration: .35s !important;
  animation-duration: .35s !important;
  animation-iteration-count: 1 !important;
}

.fadeInBlur {
  animation-name: fadeInBlur;
  transition-duration: .35s !important;
  animation-duration: .35s !important;
  animation-iteration-count: 1 !important;
}

[data-aos^="fade"][data-aos^="fade"] {
  opacity: 0;
  transition-property: opacity, transform, blur !important;
}

.spinner {
  background: radial-gradient(farthest-side, #15406a 94%, #0000) top / 9px 9px no-repeat, conic-gradient(#0000 30%, #15406a);
  border-radius: 50%;
  animation: 1s linear infinite spinner-blue;
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
  width: 56px !important;
  height: 56px !important;
}

@keyframes spinner-blue {
  100% {
    transform: rotate(1turn);
  }
}



@font-face {
  font-family: cera_pro;
  src: url("https://presentingsolutions.com/sitefiles/cerapro-medium-webfont.woff2") format("woff2"), url("https://presentingsolutions.com/sitefiles/cerapro-medium-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: cera_pro;
  src: url("https://presentingsolutions.com/sitefiles/cerapro-black-webfont.woff2") format("woff2"), url("https://presentingsolutions.com/sitefiles/cerapro-black-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

.speech-bubble-arrow {
  text-indent: -99999px;
  z-index: 1;
  width: calc(100% + 40px);
  height: 0;
  background-color: #0000;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100% 1500%;
  margin-bottom: 20px;
  margin-left: -20px;
  padding-top: 10%;
  animation-fill-mode: forwards, forwards;
  position: relative;
  overflow: hidden;
}

.hamburger {
  cursor: pointer;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: #0000;
  border: 0;
  margin: 0;
  padding: 15px;
  transition-property: opacity, filter;
  transition-duration: .15s;
  transition-timing-function: linear;
  display: inline-block;
  overflow: visible;
}

.hamburger:hover, .hamburger.is-active:hover {
  opacity: .7;
}

.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner:before, .hamburger.is-active .hamburger-inner:after {
  background-color: #000;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  margin-top: -2px;
  display: block;
  top: 50%;
}

.hamburger-inner, .hamburger-inner:before, .hamburger-inner:after {
  width: 40px;
  height: 4px;
  background-color: #000;
  border-radius: 4px;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: ease;
  position: absolute;
}

.hamburger-inner:before, .hamburger-inner:after {
  content: "";
  display: block;
}

.hamburger-inner:before {
  top: -10px;
}

.hamburger-inner:after {
  bottom: -10px;
}

.hamburger--3dx .hamburger-box {
  perspective: 80px;
}

.hamburger--3dx .hamburger-inner {
  transition: transform .15s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dx .hamburger-inner:before, .hamburger--3dx .hamburger-inner:after {
  transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dx.is-active .hamburger-inner {
  transform: rotateY(180deg);
  background-color: #0000 !important;
}

.hamburger--3dx.is-active .hamburger-inner:before {
  transform: translate3d(0, 10px, 0)rotate(45deg);
}

.hamburger--3dx.is-active .hamburger-inner:after {
  transform: translate3d(0, -10px, 0)rotate(-45deg);
}

.hamburger--3dx-r .hamburger-box {
  perspective: 80px;
}

.hamburger--3dx-r .hamburger-inner {
  transition: transform .15s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dx-r .hamburger-inner:before, .hamburger--3dx-r .hamburger-inner:after {
  transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dx-r.is-active .hamburger-inner {
  transform: rotateY(-180deg);
  background-color: #0000 !important;
}

.hamburger--3dx-r.is-active .hamburger-inner:before {
  transform: translate3d(0, 10px, 0)rotate(45deg);
}

.hamburger--3dx-r.is-active .hamburger-inner:after {
  transform: translate3d(0, -10px, 0)rotate(-45deg);
}

.hamburger--3dy .hamburger-box {
  perspective: 80px;
}

.hamburger--3dy .hamburger-inner {
  transition: transform .15s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dy .hamburger-inner:before, .hamburger--3dy .hamburger-inner:after {
  transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dy.is-active .hamburger-inner {
  transform: rotateX(-180deg);
  background-color: #0000 !important;
}

.hamburger--3dy.is-active .hamburger-inner:before {
  transform: translate3d(0, 10px, 0)rotate(45deg);
}

.hamburger--3dy.is-active .hamburger-inner:after {
  transform: translate3d(0, -10px, 0)rotate(-45deg);
}

.hamburger--3dy-r .hamburger-box {
  perspective: 80px;
}

.hamburger--3dy-r .hamburger-inner {
  transition: transform .15s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dy-r .hamburger-inner:before, .hamburger--3dy-r .hamburger-inner:after {
  transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dy-r.is-active .hamburger-inner {
  transform: rotateX(180deg);
  background-color: #0000 !important;
}

.hamburger--3dy-r.is-active .hamburger-inner:before {
  transform: translate3d(0, 10px, 0)rotate(45deg);
}

.hamburger--3dy-r.is-active .hamburger-inner:after {
  transform: translate3d(0, -10px, 0)rotate(-45deg);
}

.hamburger--3dxy .hamburger-box {
  perspective: 80px;
}

.hamburger--3dxy .hamburger-inner {
  transition: transform .15s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dxy .hamburger-inner:before, .hamburger--3dxy .hamburger-inner:after {
  transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dxy.is-active .hamburger-inner {
  transform: rotateX(180deg)rotateY(180deg);
  background-color: #0000 !important;
}

.hamburger--3dxy.is-active .hamburger-inner:before {
  transform: translate3d(0, 10px, 0)rotate(45deg);
}

.hamburger--3dxy.is-active .hamburger-inner:after {
  transform: translate3d(0, -10px, 0)rotate(-45deg);
}

.hamburger--3dxy-r .hamburger-box {
  perspective: 80px;
}

.hamburger--3dxy-r .hamburger-inner {
  transition: transform .15s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dxy-r .hamburger-inner:before, .hamburger--3dxy-r .hamburger-inner:after {
  transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dxy-r.is-active .hamburger-inner {
  transform: rotateX(180deg)rotateY(180deg)rotateZ(-180deg);
  background-color: #0000 !important;
}

.hamburger--3dxy-r.is-active .hamburger-inner:before {
  transform: translate3d(0, 10px, 0)rotate(45deg);
}

.hamburger--3dxy-r.is-active .hamburger-inner:after {
  transform: translate3d(0, -10px, 0)rotate(-45deg);
}

.hamburger--arrow.is-active .hamburger-inner:before {
  transform: translate3d(-8px, 0, 0)rotate(-45deg)scale(.7, 1);
}

.hamburger--arrow.is-active .hamburger-inner:after {
  transform: translate3d(-8px, 0, 0)rotate(45deg)scale(.7, 1);
}

.hamburger--arrow-r.is-active .hamburger-inner:before {
  transform: translate3d(8px, 0, 0)rotate(45deg)scale(.7, 1);
}

.hamburger--arrow-r.is-active .hamburger-inner:after {
  transform: translate3d(8px, 0, 0)rotate(-45deg)scale(.7, 1);
}

.hamburger--arrowalt .hamburger-inner:before {
  transition: top .1s .1s, transform .1s cubic-bezier(.165, .84, .44, 1);
}

.hamburger--arrowalt .hamburger-inner:after {
  transition: bottom .1s .1s, transform .1s cubic-bezier(.165, .84, .44, 1);
}

.hamburger--arrowalt.is-active .hamburger-inner:before {
  transition: top .1s, transform .1s cubic-bezier(.895, .03, .685, .22) .1s;
  top: 0;
  transform: translate3d(-8px, -10px, 0)rotate(-45deg)scale(.7, 1);
}

.hamburger--arrowalt.is-active .hamburger-inner:after {
  transition: bottom .1s, transform .1s cubic-bezier(.895, .03, .685, .22) .1s;
  bottom: 0;
  transform: translate3d(-8px, 10px, 0)rotate(45deg)scale(.7, 1);
}

.hamburger--arrowalt-r .hamburger-inner:before {
  transition: top .1s .1s, transform .1s cubic-bezier(.165, .84, .44, 1);
}

.hamburger--arrowalt-r .hamburger-inner:after {
  transition: bottom .1s .1s, transform .1s cubic-bezier(.165, .84, .44, 1);
}

.hamburger--arrowalt-r.is-active .hamburger-inner:before {
  transition: top .1s, transform .1s cubic-bezier(.895, .03, .685, .22) .1s;
  top: 0;
  transform: translate3d(8px, -10px, 0)rotate(45deg)scale(.7, 1);
}

.hamburger--arrowalt-r.is-active .hamburger-inner:after {
  transition: bottom .1s, transform .1s cubic-bezier(.895, .03, .685, .22) .1s;
  bottom: 0;
  transform: translate3d(8px, 10px, 0)rotate(-45deg)scale(.7, 1);
}

.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg);
}

.hamburger--arrowturn.is-active .hamburger-inner:before {
  transform: translate3d(8px, 0, 0)rotate(45deg)scale(.7, 1);
}

.hamburger--arrowturn.is-active .hamburger-inner:after {
  transform: translate3d(8px, 0, 0)rotate(-45deg)scale(.7, 1);
}

.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg);
}

.hamburger--arrowturn-r.is-active .hamburger-inner:before {
  transform: translate3d(-8px, 0, 0)rotate(-45deg)scale(.7, 1);
}

.hamburger--arrowturn-r.is-active .hamburger-inner:after {
  transform: translate3d(-8px, 0, 0)rotate(45deg)scale(.7, 1);
}

.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner:before, .hamburger--boring .hamburger-inner:after {
  transition-property: none;
}

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg);
}

.hamburger--boring.is-active .hamburger-inner:before {
  opacity: 0;
  top: 0;
}

.hamburger--boring.is-active .hamburger-inner:after {
  bottom: 0;
  transform: rotate(-90deg);
}

.hamburger--collapse .hamburger-inner {
  transition-duration: .13s;
  transition-delay: .13s;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  top: auto;
  bottom: 0;
}

.hamburger--collapse .hamburger-inner:after {
  transition: top .2s cubic-bezier(.33333, .66667, .66667, 1) .2s, opacity .1s linear;
  top: -20px;
}

.hamburger--collapse .hamburger-inner:before {
  transition: top .12s cubic-bezier(.33333, .66667, .66667, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19);
}

.hamburger--collapse.is-active .hamburger-inner {
  transition-delay: .22s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transform: translate3d(0, -10px, 0)rotate(-45deg);
}

.hamburger--collapse.is-active .hamburger-inner:after {
  opacity: 0;
  transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), opacity .1s linear .22s;
  top: 0;
}

.hamburger--collapse.is-active .hamburger-inner:before {
  transition: top .1s cubic-bezier(.33333, 0, .66667, .33333) .16s, transform .13s cubic-bezier(.215, .61, .355, 1) .25s;
  top: 0;
  transform: rotate(-90deg);
}

.hamburger--collapse-r .hamburger-inner {
  transition-duration: .13s;
  transition-delay: .13s;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  top: auto;
  bottom: 0;
}

.hamburger--collapse-r .hamburger-inner:after {
  transition: top .2s cubic-bezier(.33333, .66667, .66667, 1) .2s, opacity .1s linear;
  top: -20px;
}

.hamburger--collapse-r .hamburger-inner:before {
  transition: top .12s cubic-bezier(.33333, .66667, .66667, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19);
}

.hamburger--collapse-r.is-active .hamburger-inner {
  transition-delay: .22s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transform: translate3d(0, -10px, 0)rotate(45deg);
}

.hamburger--collapse-r.is-active .hamburger-inner:after {
  opacity: 0;
  transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), opacity .1s linear .22s;
  top: 0;
}

.hamburger--collapse-r.is-active .hamburger-inner:before {
  transition: top .1s cubic-bezier(.33333, 0, .66667, .33333) .16s, transform .13s cubic-bezier(.215, .61, .355, 1) .25s;
  top: 0;
  transform: rotate(90deg);
}

.hamburger--elastic .hamburger-inner {
  transition-duration: .275s;
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
  top: 2px;
}

.hamburger--elastic .hamburger-inner:before {
  transition: opacity .125s .275s;
  top: 10px;
}

.hamburger--elastic .hamburger-inner:after {
  transition: transform .275s cubic-bezier(.68, -.55, .265, 1.55);
  top: 20px;
}

.hamburger--elastic.is-active .hamburger-inner {
  transition-delay: 75ms;
  transform: translate3d(0, 10px, 0)rotate(135deg);
}

.hamburger--elastic.is-active .hamburger-inner:before {
  opacity: 0;
  transition-delay: 0s;
}

.hamburger--elastic.is-active .hamburger-inner:after {
  transition-delay: 75ms;
  transform: translate3d(0, -20px, 0)rotate(-270deg);
}

.hamburger--elastic-r .hamburger-inner {
  transition-duration: .275s;
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
  top: 2px;
}

.hamburger--elastic-r .hamburger-inner:before {
  transition: opacity .125s .275s;
  top: 10px;
}

.hamburger--elastic-r .hamburger-inner:after {
  transition: transform .275s cubic-bezier(.68, -.55, .265, 1.55);
  top: 20px;
}

.hamburger--elastic-r.is-active .hamburger-inner {
  transition-delay: 75ms;
  transform: translate3d(0, 10px, 0)rotate(-135deg);
}

.hamburger--elastic-r.is-active .hamburger-inner:before {
  opacity: 0;
  transition-delay: 0s;
}

.hamburger--elastic-r.is-active .hamburger-inner:after {
  transition-delay: 75ms;
  transform: translate3d(0, -20px, 0)rotate(270deg);
}

.hamburger--emphatic {
  overflow: hidden;
}

.hamburger--emphatic .hamburger-inner {
  transition: background-color .125s ease-in .175s;
}

.hamburger--emphatic .hamburger-inner:before {
  transition: transform .125s cubic-bezier(.6, .04, .98, .335), top 50ms linear .125s, left .125s ease-in .175s;
  left: 0;
}

.hamburger--emphatic .hamburger-inner:after {
  transition: transform .125s cubic-bezier(.6, .04, .98, .335), top 50ms linear .125s, right .125s ease-in .175s;
  top: 10px;
  right: 0;
}

.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: #0000 !important;
}

.hamburger--emphatic.is-active .hamburger-inner:before {
  transition: left .125s ease-out, top 50ms linear .125s, transform .125s cubic-bezier(.075, .82, .165, 1) .175s;
  top: -80px;
  left: -80px;
  transform: translate3d(80px, 80px, 0)rotate(45deg);
}

.hamburger--emphatic.is-active .hamburger-inner:after {
  transition: right .125s ease-out, top 50ms linear .125s, transform .125s cubic-bezier(.075, .82, .165, 1) .175s;
  top: -80px;
  right: -80px;
  transform: translate3d(-80px, 80px, 0)rotate(-45deg);
}

.hamburger--emphatic-r {
  overflow: hidden;
}

.hamburger--emphatic-r .hamburger-inner {
  transition: background-color .125s ease-in .175s;
}

.hamburger--emphatic-r .hamburger-inner:before {
  transition: transform .125s cubic-bezier(.6, .04, .98, .335), top 50ms linear .125s, left .125s ease-in .175s;
  left: 0;
}

.hamburger--emphatic-r .hamburger-inner:after {
  transition: transform .125s cubic-bezier(.6, .04, .98, .335), top 50ms linear .125s, right .125s ease-in .175s;
  top: 10px;
  right: 0;
}

.hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: #0000 !important;
}

.hamburger--emphatic-r.is-active .hamburger-inner:before {
  transition: left .125s ease-out, top 50ms linear .125s, transform .125s cubic-bezier(.075, .82, .165, 1) .175s;
  top: 80px;
  left: -80px;
  transform: translate3d(80px, -80px, 0)rotate(-45deg);
}

.hamburger--emphatic-r.is-active .hamburger-inner:after {
  transition: right .125s ease-out, top 50ms linear .125s, transform .125s cubic-bezier(.075, .82, .165, 1) .175s;
  top: 80px;
  right: -80px;
  transform: translate3d(-80px, -80px, 0)rotate(45deg);
}

.hamburger--minus .hamburger-inner:before, .hamburger--minus .hamburger-inner:after {
  transition: bottom 80ms ease-out, top 80ms ease-out, opacity linear;
}

.hamburger--minus.is-active .hamburger-inner:before, .hamburger--minus.is-active .hamburger-inner:after {
  opacity: 0;
  transition: bottom 80ms ease-out, top 80ms ease-out, opacity 0s linear 80ms;
}

.hamburger--minus.is-active .hamburger-inner:before {
  top: 0;
}

.hamburger--minus.is-active .hamburger-inner:after {
  bottom: 0;
}

.hamburger--slider .hamburger-inner {
  top: 2px;
}

.hamburger--slider .hamburger-inner:before {
  transition-property: transform, opacity;
  transition-duration: .15s;
  transition-timing-function: ease;
  top: 10px;
}

.hamburger--slider .hamburger-inner:after {
  top: 20px;
}

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0)rotate(45deg);
}

.hamburger--slider.is-active .hamburger-inner:before {
  opacity: 0;
  transform: rotate(-45deg)translate3d(-5.71429px, -6px, 0);
}

.hamburger--slider.is-active .hamburger-inner:after {
  transform: translate3d(0, -20px, 0)rotate(-90deg);
}

.hamburger--slider-r .hamburger-inner {
  top: 2px;
}

.hamburger--slider-r .hamburger-inner:before {
  transition-property: transform, opacity;
  transition-duration: .15s;
  transition-timing-function: ease;
  top: 10px;
}

.hamburger--slider-r .hamburger-inner:after {
  top: 20px;
}

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0)rotate(-45deg);
}

.hamburger--slider-r.is-active .hamburger-inner:before {
  opacity: 0;
  transform: rotate(45deg)translate3d(5.71429px, -6px, 0);
}

.hamburger--slider-r.is-active .hamburger-inner:after {
  transform: translate3d(0, -20px, 0)rotate(90deg);
}

.hamburger--spin .hamburger-inner {
  transition-duration: .22s;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
}

.hamburger--spin .hamburger-inner:before {
  transition: top .1s ease-in .25s, opacity .1s ease-in;
}

.hamburger--spin .hamburger-inner:after {
  transition: bottom .1s ease-in .25s, transform .22s cubic-bezier(.55, .055, .675, .19);
}

.hamburger--spin.is-active .hamburger-inner {
  transition-delay: .12s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transform: rotate(225deg);
}

.hamburger--spin.is-active .hamburger-inner:before {
  opacity: 0;
  transition: top .1s ease-out, opacity .1s ease-out .12s;
  top: 0;
}

.hamburger--spin.is-active .hamburger-inner:after {
  transition: bottom .1s ease-out, transform .22s cubic-bezier(.215, .61, .355, 1) .12s;
  bottom: 0;
  transform: rotate(-90deg);
}

.hamburger--spin-r .hamburger-inner {
  transition-duration: .22s;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
}

.hamburger--spin-r .hamburger-inner:before {
  transition: top .1s ease-in .25s, opacity .1s ease-in;
}

.hamburger--spin-r .hamburger-inner:after {
  transition: bottom .1s ease-in .25s, transform .22s cubic-bezier(.55, .055, .675, .19);
}

.hamburger--spin-r.is-active .hamburger-inner {
  transition-delay: .12s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transform: rotate(-225deg);
}

.hamburger--spin-r.is-active .hamburger-inner:before {
  opacity: 0;
  transition: top .1s ease-out, opacity .1s ease-out .12s;
  top: 0;
}

.hamburger--spin-r.is-active .hamburger-inner:after {
  transition: bottom .1s ease-out, transform .22s cubic-bezier(.215, .61, .355, 1) .12s;
  bottom: 0;
  transform: rotate(90deg);
}

.hamburger--spring .hamburger-inner {
  transition: background-color 0s linear .13s;
  top: 2px;
}

.hamburger--spring .hamburger-inner:before {
  transition: top .1s cubic-bezier(.33333, .66667, .66667, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19);
  top: 10px;
}

.hamburger--spring .hamburger-inner:after {
  transition: top .2s cubic-bezier(.33333, .66667, .66667, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19);
  top: 20px;
}

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: .22s;
  background-color: #0000 !important;
}

.hamburger--spring.is-active .hamburger-inner:before {
  transition: top .1s cubic-bezier(.33333, 0, .66667, .33333) .15s, transform .13s cubic-bezier(.215, .61, .355, 1) .22s;
  top: 0;
  transform: translate3d(0, 10px, 0)rotate(45deg);
}

.hamburger--spring.is-active .hamburger-inner:after {
  transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), transform .13s cubic-bezier(.215, .61, .355, 1) .22s;
  top: 0;
  transform: translate3d(0, 10px, 0)rotate(-45deg);
}

.hamburger--spring-r .hamburger-inner {
  transition-duration: .13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  top: auto;
  bottom: 0;
}

.hamburger--spring-r .hamburger-inner:after {
  transition: top .2s cubic-bezier(.33333, .66667, .66667, 1) .2s, opacity linear;
  top: -20px;
}

.hamburger--spring-r .hamburger-inner:before {
  transition: top .1s cubic-bezier(.33333, .66667, .66667, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19);
}

.hamburger--spring-r.is-active .hamburger-inner {
  transition-delay: .22s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transform: translate3d(0, -10px, 0)rotate(-45deg);
}

.hamburger--spring-r.is-active .hamburger-inner:after {
  opacity: 0;
  transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), opacity 0s linear .22s;
  top: 0;
}

.hamburger--spring-r.is-active .hamburger-inner:before {
  transition: top .1s cubic-bezier(.33333, 0, .66667, .33333) .15s, transform .13s cubic-bezier(.215, .61, .355, 1) .22s;
  top: 0;
  transform: rotate(90deg);
}

.hamburger--stand .hamburger-inner {
  transition: transform 75ms cubic-bezier(.55, .055, .675, .19) .15s, background-color 0s linear 75ms;
}

.hamburger--stand .hamburger-inner:before {
  transition: top 75ms ease-in 75ms, transform 75ms cubic-bezier(.55, .055, .675, .19);
}

.hamburger--stand .hamburger-inner:after {
  transition: bottom 75ms ease-in 75ms, transform 75ms cubic-bezier(.55, .055, .675, .19);
}

.hamburger--stand.is-active .hamburger-inner {
  transition: transform 75ms cubic-bezier(.215, .61, .355, 1), background-color 0s linear .15s;
  transform: rotate(90deg);
  background-color: #0000 !important;
}

.hamburger--stand.is-active .hamburger-inner:before {
  transition: top 75ms ease-out .1s, transform 75ms cubic-bezier(.215, .61, .355, 1) .15s;
  top: 0;
  transform: rotate(-45deg);
}

.hamburger--stand.is-active .hamburger-inner:after {
  transition: bottom 75ms ease-out .1s, transform 75ms cubic-bezier(.215, .61, .355, 1) .15s;
  bottom: 0;
  transform: rotate(45deg);
}

.hamburger--stand-r .hamburger-inner {
  transition: transform 75ms cubic-bezier(.55, .055, .675, .19) .15s, background-color 0s linear 75ms;
}

.hamburger--stand-r .hamburger-inner:before {
  transition: top 75ms ease-in 75ms, transform 75ms cubic-bezier(.55, .055, .675, .19);
}

.hamburger--stand-r .hamburger-inner:after {
  transition: bottom 75ms ease-in 75ms, transform 75ms cubic-bezier(.55, .055, .675, .19);
}

.hamburger--stand-r.is-active .hamburger-inner {
  transition: transform 75ms cubic-bezier(.215, .61, .355, 1), background-color 0s linear .15s;
  transform: rotate(-90deg);
  background-color: #0000 !important;
}

.hamburger--stand-r.is-active .hamburger-inner:before {
  transition: top 75ms ease-out .1s, transform 75ms cubic-bezier(.215, .61, .355, 1) .15s;
  top: 0;
  transform: rotate(-45deg);
}

.hamburger--stand-r.is-active .hamburger-inner:after {
  transition: bottom 75ms ease-out .1s, transform 75ms cubic-bezier(.215, .61, .355, 1) .15s;
  bottom: 0;
  transform: rotate(45deg);
}

.hamburger--squeeze .hamburger-inner {
  transition-duration: 75ms;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
}

.hamburger--squeeze .hamburger-inner:before {
  transition: top 75ms .12s, opacity 75ms;
}

.hamburger--squeeze .hamburger-inner:after {
  transition: bottom 75ms .12s, transform 75ms cubic-bezier(.55, .055, .675, .19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  transition-delay: .12s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transform: rotate(45deg);
}

.hamburger--squeeze.is-active .hamburger-inner:before {
  opacity: 0;
  transition: top 75ms, opacity 75ms .12s;
  top: 0;
}

.hamburger--squeeze.is-active .hamburger-inner:after {
  transition: bottom 75ms, transform 75ms cubic-bezier(.215, .61, .355, 1) .12s;
  bottom: 0;
  transform: rotate(-90deg);
}

.hamburger--vortex .hamburger-inner {
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.19, 1, .22, 1);
}

.hamburger--vortex .hamburger-inner:before, .hamburger--vortex .hamburger-inner:after {
  transition-duration: 0s;
  transition-delay: .1s;
  transition-timing-function: linear;
}

.hamburger--vortex .hamburger-inner:before {
  transition-property: top, opacity;
}

.hamburger--vortex .hamburger-inner:after {
  transition-property: bottom, transform;
}

.hamburger--vortex.is-active .hamburger-inner {
  transition-timing-function: cubic-bezier(.19, 1, .22, 1);
  transform: rotate(765deg);
}

.hamburger--vortex.is-active .hamburger-inner:before, .hamburger--vortex.is-active .hamburger-inner:after {
  transition-delay: 0s;
}

.hamburger--vortex.is-active .hamburger-inner:before {
  opacity: 0;
  top: 0;
}

.hamburger--vortex.is-active .hamburger-inner:after {
  bottom: 0;
  transform: rotate(90deg);
}

.hamburger--vortex-r .hamburger-inner {
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.19, 1, .22, 1);
}

.hamburger--vortex-r .hamburger-inner:before, .hamburger--vortex-r .hamburger-inner:after {
  transition-duration: 0s;
  transition-delay: .1s;
  transition-timing-function: linear;
}

.hamburger--vortex-r .hamburger-inner:before {
  transition-property: top, opacity;
}

.hamburger--vortex-r .hamburger-inner:after {
  transition-property: bottom, transform;
}

.hamburger--vortex-r.is-active .hamburger-inner {
  transition-timing-function: cubic-bezier(.19, 1, .22, 1);
  transform: rotate(-765deg);
}

.hamburger--vortex-r.is-active .hamburger-inner:before, .hamburger--vortex-r.is-active .hamburger-inner:after {
  transition-delay: 0s;
}

.hamburger--vortex-r.is-active .hamburger-inner:before {
  opacity: 0;
  top: 0;
}

.hamburger--vortex-r.is-active .hamburger-inner:after {
  bottom: 0;
  transform: rotate(-90deg);
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.z-10 {
  z-index: 10;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-auto {
  height: auto;
}

.w-48 {
  width: 12rem;
}

.w-full {
  width: 100%;
}

.w-64 {
  width: 16rem;
}

.grow {
  flex-grow: 1;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.self-center {
  align-self: center;
}

.rounded-full {
  border-radius: 9999px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-tuftsy-silver {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(181 214 232 / var(--tw-border-opacity));
}

.bg-blue-lightest {
  --tw-bg-opacity: 1;
  background-color: rgb(247 249 250 / var(--tw-bg-opacity));
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pt-14 {
  padding-top: 3.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-sans {
  font-family: cera_pro, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.font-handwriting {
  font-family: Qwitcher Grypen, cursive;
}

.text-base {
  font-size: 22px;
  line-height: 30px;
}

.text-lg {
  font-size: 28px;
  line-height: 32px;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-xl {
  font-size: 36px;
  line-height: 36px;
}

.font-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-blue {
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
}

.opacity-100 {
  opacity: 1;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

body[data-slug="/"].loaded nav.main-menu.loading {
  opacity: 1;
  top: 0;
}

body[data-slug="/"].loaded .hero .container {
  max-width: max-content;
}

body[data-slug="/"].loaded .hero .container h1, body[data-slug="/"].loaded .hero .container h2 {
  opacity: 1;
}

body[data-slug="/"] nav.main-menu.loading {
  opacity: 0;
  transition: all 1s cubic-bezier(.4, 0, .2, 1) 2s;
  top: -20rem;
}

body[data-slug="/"] .hero {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-color: rgb(21 64 106 / var(--tw-border-opacity));
  background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  --tw-gradient-from: #fff;
  --tw-gradient-to: #fff0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #fff0;
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to);
  --tw-gradient-to: #edf3fc;
  border-radius: 0;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

@media (min-width: 768px) {
  body[data-slug="/"] .hero {
    min-height: 100vh;
  }
}

body[data-slug="/"] .hero {
  box-shadow: none;
}

body[data-slug="/"] .hero .container {
  align-items: flex-start;
}

@media (min-width: 768px) {
  body[data-slug="/"] .hero .container {
    min-height: 100vh;
  }
}

body[data-slug="/"] .hero .container h1, body[data-slug="/"] .hero .container h2 {
  text-align: left;
  text-transform: none;
  opacity: 0;
  font-family: adobe-caslon-pro;
  font-size: 2.25rem;
  line-height: 1.5;
  transition-property: opacity;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
}

@media (min-width: 768px) {
  body[data-slug="/"] .hero .container h1, body[data-slug="/"] .hero .container h2 {
    font-size: 4.5rem;
    line-height: 1;
  }
}

body[data-slug="/"] .hero .container h1 {
  margin-bottom: 8rem;
  transition-delay: .2s;
}

body[data-slug="/"] .hero .container h2 {
  transition-delay: 1.25s;
}

body[data-slug="/"] .people-testimonials {
  position: relative;
}

body[data-slug="/"] .people-testimonials .wave {
  z-index: 10;
  height: 100%;
  width: 100%;
  background-image: url("https://presentingsolutions.com/sitefiles/p-wave-motion.png");
  background-position: 0 -5rem;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

body[data-slug="/"] .people-testimonials:before, body[data-slug="/"] .people-testimonials:after {
  height: 100%;
  width: 100%;
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
}

body[data-slug="/"] .people-testimonials:before {
  pointer-events: none;
  z-index: 0;
  opacity: .5;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23f0f0f0' fill-opacity='1' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
}

body[data-slug="/"] .people-testimonials:after {
  z-index: 0;
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  --tw-gradient-from: #fff;
  --tw-gradient-to: #fff0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #fff0;
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to);
  --tw-gradient-to: transparent;
}

body[data-slug="/"] .people-testimonials .container {
  z-index: 20;
  position: relative;
}

body[data-slug="/"] .people-testimonials .container.logo {
  padding-top: 3rem;
}

@media (min-width: 768px) {
  body[data-slug="/"] .people-testimonials .container.logo {
    padding-top: 6rem;
  }
}

body[data-slug="/"] .people-testimonials .container img.logo {
  width: 100vw;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@media (min-width: 768px) {
  body[data-slug="/"] .people-testimonials .container img.logo {
    max-width: 32rem;
    margin-bottom: 6rem;
  }
}

body[data-slug="/"] .people-testimonials .container blockquote {
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
  align-items: center;
  padding-bottom: 1rem;
  font-size: 22px;
  line-height: 2;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  body[data-slug="/"] .people-testimonials .container blockquote {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

body[data-slug="/"] .people-testimonials .container blockquote p {
  margin: 0;
  padding: 0;
}

body[data-slug="/"] .people-testimonials .container blockquote:before {
  content: " ";
  width: 4rem;
  height: 5rem;
  background: url("https://presentingsolutions.com/sitefiles/small-double-quotes.png") 0 0 / contain no-repeat;
  position: absolute;
  top: -1rem;
  left: -5rem;
}

body[data-slug="/"] .people-testimonials .container blockquote footer {
  display: none;
}

body[data-slug="/"] .people-testimonials img.speech-line {
  z-index: 20;
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 8rem;
  padding-right: 8rem;
  display: block;
  position: relative;
}

body[data-slug="/"] .people-testimonials .circle-item-list {
  margin-top: 0;
  padding-bottom: 5rem;
}

body[data-slug="/"] .our-work {
  z-index: 30;
  --tw-bg-opacity: 1;
  background-image: url("https://presentingsolutions.com/sitefiles/peeking-p-1.png");
  background-position: 0 -5rem;
  background-repeat: no-repeat;
  background-size: 60%;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0px 20px 30px #15406a29;
  --tw-shadow-colored: 0px 20px 30px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-bottom-left-radius: 26px;
  border-bottom-right-radius: 26px;
  margin-top: 1rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
  position: relative;
}

body[data-slug="/"] .our-work a {
  font-weight: 700;
}

body[data-slug="/"] .our-work h3 {
  text-align: center;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
  margin-bottom: 2.5rem;
  font-family: cera_pro, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 3rem;
  line-height: 1;
}

body[data-slug="/"] .our-work h4 {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
  margin-bottom: 5rem;
  font-size: 1.875rem;
  line-height: 1.625;
}

body[data-slug="/"] .our-work h5 {
  text-align: center;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
  margin-bottom: 1rem;
  font-family: cera_pro, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1.875rem;
  line-height: 2.25rem;
}

body[data-slug="/"] .our-work .work-type {
  max-width: 56rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 22px;
  line-height: 30px;
}

@media (min-width: 768px) {
  body[data-slug="/"] .our-work .work-type {
    display: flex;
  }
}

body[data-slug="/"] .our-work .work-type a {
  width: 5rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@media (min-width: 768px) {
  body[data-slug="/"] .our-work .work-type a {
    width: 9rem;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 3.5rem;
  }
}

body[data-slug="/"] .our-work .work-type a img {
  display: block;
}

@media (min-width: 768px) {
  body[data-slug="/"] .our-work .work-type a img {
    width: 100%;
  }
}

body[data-slug="/"] .our-work .work-type p {
  margin-bottom: 0;
}

body[data-slug="/"] .our-work h6 {
  text-align: center;
  margin-bottom: 4rem;
  font-size: 22px;
  line-height: 30px;
}

body[data-slug="/"] .our-work .ctas {
  max-width: 48rem;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  body[data-slug="/"] .our-work .ctas {
    display: flex;
  }
}

body[data-slug="/"] .our-work .ctas button, body[data-slug="/"] .our-work .ctas a {
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  display: block;
}

@media (min-width: 768px) {
  body[data-slug="/"] .our-work .ctas button, body[data-slug="/"] .our-work .ctas a {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    display: inline-block;
  }
}

body[data-slug="/"] .well .bgd {
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  opacity: .25;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 0;
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 768px) {
  body[data-slug="/"] .well .bgd {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

body[data-slug="/"] .well .bgd {
  overflow-x: hidden;
  overflow-y: visible;
}

body[data-slug="/"] .well .bgd div {
  width: 100%;
  position: relative;
}

body[data-slug="/"] .well .bgd img {
  height: auto;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

body[data-slug="/"] .well h1, body[data-slug="/"] .well h2, body[data-slug="/"] .well h3, body[data-slug="/"] .well h4 {
  max-width: 64rem;
  text-align: center;
}

@media (min-width: 768px) {
  body[data-slug="/"] .well h1, body[data-slug="/"] .well h2, body[data-slug="/"] .well h3, body[data-slug="/"] .well h4 {
    padding: 6rem 3.5rem 5rem;
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

body[data-slug="/"] .well h1, body[data-slug="/"] .well h2, body[data-slug="/"] .well h3, body[data-slug="/"] .well h4 {
  line-height: 2;
}

body[data-slug="/"] .company-testimonials {
  --tw-shadow: 0px -20px 30px #15406a29;
  --tw-shadow-colored: 0px -20px 30px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  margin-top: 6rem;
  position: relative;
}

@media (min-width: 768px) {
  body[data-slug="/"] .company-testimonials {
    margin-top: 1rem;
  }
}

body[data-slug="/"] .company-testimonials .quote {
  display: block;
  position: absolute;
}

@media (min-width: 768px) {
  body[data-slug="/"] .company-testimonials .quote {
    margin-bottom: -2.5rem;
  }
}

body[data-slug="/"] .company-testimonials .quote {
  content: " ";
  width: 12rem;
  height: 15rem;
  background: url("https://presentingsolutions.com/sitefiles/large-double-quotes.png") 0 0 / contain no-repeat;
  top: -3rem;
  left: 1rem;
}

body[data-slug="/"] .company-testimonials .container {
  position: relative;
}

body[data-slug="/"] .company-testimonials .no-scroll {
  overflow-x: hidden;
}

body[data-slug="/"] .company-testimonials .bgd {
  height: 100%;
  width: 100%;
  opacity: .1;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

body[data-slug="/"] .company-testimonials .bgd .bgd-before {
  opacity: .7;
  content: " ";
  width: 70rem;
  height: 70rem;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 9999px;
  display: block;
  position: absolute;
  top: -10rem;
  right: -20rem;
}

body[data-slug="/"] .company-testimonials blockquote {
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
  padding-top: 12rem;
  font-size: 22px;
  line-height: 2;
  position: relative;
}

@media (min-width: 768px) {
  body[data-slug="/"] .company-testimonials blockquote {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

body[data-slug="/"] .company-testimonials blockquote footer {
  display: none;
}

body[data-slug="/"] .company-testimonials .circle-item-list {
  margin-top: 2.5rem;
  padding-bottom: 5rem;
  display: flex;
  position: relative;
}

body[data-slug="/"] .company-testimonials .circle-item-list ul {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin-left: auto;
  margin-right: auto;
}

body[data-slug="/"] .company-testimonials .circle-item-list ul li a {
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 22px;
  line-height: 30px;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: relative;
}

@media (min-width: 768px) {
  body[data-slug="/"] .company-testimonials .circle-item-list ul li a {
    --tw-scale-x: .5;
    --tw-scale-y: .5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

body[data-slug="/"] .company-testimonials .circle-item-list ul li a {
  padding-bottom: 2.5rem;
}

body[data-slug="/"] .company-testimonials .circle-item-list ul li a img {
  opacity: 1;
}

body[data-slug="/"] .company-testimonials .circle-item-list ul li a strong, body[data-slug="/"] .company-testimonials .circle-item-list ul li a em {
  width: 100%;
  text-align: center;
  letter-spacing: -.025em;
  display: none;
  left: 0;
}

@media (min-width: 768px) {
  body[data-slug="/"] .company-testimonials .circle-item-list ul li a strong, body[data-slug="/"] .company-testimonials .circle-item-list ul li a em {
    position: absolute;
  }
}

body[data-slug="/"] .company-testimonials .circle-item-list ul li a strong {
  font-size: 16px;
  line-height: 20px;
}

@media (min-width: 768px) {
  body[data-slug="/"] .company-testimonials .circle-item-list ul li a strong {
    font-size: 22px;
    line-height: 30px;
    bottom: 0;
  }
}

body[data-slug="/"] .company-testimonials .circle-item-list ul li a em {
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: 768px) {
  body[data-slug="/"] .company-testimonials .circle-item-list ul li a em {
    font-size: 16px;
    line-height: 20px;
    bottom: -.25rem;
  }
}

body[data-slug="/"] .company-testimonials .circle-item-list ul li a.active {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

body[data-slug="/"] .company-testimonials .circle-item-list ul li a.active strong, body[data-slug="/"] .company-testimonials .circle-item-list ul li a.active em {
  display: block;
}

body[data-slug="/"] .company-testimonials .circle-item-list ul li a:hover:not(.active) {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

body[data-slug="/"] .footer-form .form-wrapper h6 {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

body[data-slug="/"] .what-we-do {
  z-index: 20;
  --tw-bg-opacity: 1;
  background-image: url("https://presentingsolutions.com/sitefiles/p-wave-balance.png");
  background-position: 50% -5rem;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
  --tw-shadow: 0px 20px 30px #15406a29;
  --tw-shadow-colored: 0px 20px 30px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-bottom-left-radius: 26px;
  border-bottom-right-radius: 26px;
  margin-top: -2.5rem;
  padding-top: 8rem;
  padding-bottom: 4rem;
  position: relative;
}

body[data-slug="/"] .what-we-do h3 {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 3rem;
  line-height: 1;
}

body[data-slug="/"] .what-we-do p {
  font-size: 1.875rem;
  line-height: 1.625;
}

body[data-slug="/"] .what-we-do .services div.service-row div.container {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

@media (min-width: 768px) {
  body[data-slug="/"] .what-we-do .services div.service-row div.container {
    display: grid;
  }
}

body[data-slug="/"] .what-we-do .services div.service-row div.container div {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(181 214 232 / var(--tw-border-opacity));
  --tw-border-opacity: 1;
  border-right-color: rgb(181 214 232 / var(--tw-border-opacity));
  padding: 1rem .5rem 0;
}

@media (min-width: 768px) {
  body[data-slug="/"] .what-we-do .services div.service-row div.container div {
    padding: 2rem;
  }
}

body[data-slug="/"] .what-we-do .services div.service-row div.container div {
  margin-top: -1px;
}

body[data-slug="/"] .what-we-do .services div.service-row div.container div:nth-child(2n) {
  margin-left: -1px;
}

body[data-slug="/"] .what-we-do .services div.service-row:last-of-type div.container div {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-bottom-color: rgb(181 214 232 / var(--tw-border-opacity));
}

body[data-slug="/"] .what-we-do .services div.service-row h6 {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-family: cera_pro, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1.875rem;
  line-height: 2.25rem;
}

body[data-slug="/"] .what-we-do .services div.service-row h6 strong {
  --tw-text-opacity: 1;
  color: rgb(66 115 163 / var(--tw-text-opacity));
  font-weight: 400;
}

body[data-slug="/"] .what-we-do .services div.service-row ol {
  margin-bottom: .5rem;
  font-size: 22px;
  line-height: 30px;
  display: inline-block;
}

body[data-slug="/"] .what-we-do .services div.service-row ol li {
  margin-bottom: 1.5rem;
  margin-left: 3rem;
}

@media (min-width: 768px) {
  body[data-slug="/"] .what-we-do .services div.service-row ol li {
    margin-left: 5rem;
  }
}

body[data-slug="/"] .what-we-do .services div.service-row ol li:before, body[data-slug="/"] .what-we-do .services div.service-row ol li:after, body[data-slug="/"] .what-we-do .services div.service-row ol li span:before, body[data-slug="/"] .what-we-do .services div.service-row ol li span:after {
  height: 1.5em;
}

body[data-slug="/"] .what-we-do .cta {
  text-align: center;
  padding-top: 4rem;
}

@media (min-width: 768px) {
  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .md\:ml-16 {
    margin-left: 4rem;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:ml-12 {
    margin-left: 3rem;
  }

  .md\:mr-10 {
    margin-right: 2.5rem;
  }

  .md\:ml-8 {
    margin-left: 2rem;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:w-3\/5 {
    width: 60%;
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:text-left {
    text-align: left;
  }
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.z-10 {
  z-index: 10;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-auto {
  height: auto;
}

.w-48 {
  width: 12rem;
}

.w-full {
  width: 100%;
}

.w-64 {
  width: 16rem;
}

.grow {
  flex-grow: 1;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.self-center {
  align-self: center;
}

.rounded-full {
  border-radius: 9999px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-tuftsy-silver {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(181 214 232 / var(--tw-border-opacity));
}

.bg-blue-lightest {
  --tw-bg-opacity: 1;
  background-color: rgb(247 249 250 / var(--tw-bg-opacity));
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pt-14 {
  padding-top: 3.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-sans {
  font-family: cera_pro, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.font-handwriting {
  font-family: Qwitcher Grypen, cursive;
}

.text-base {
  font-size: 22px;
  line-height: 30px;
}

.text-lg {
  font-size: 28px;
  line-height: 32px;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-xl {
  font-size: 36px;
  line-height: 36px;
}

.font-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-blue {
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
}

.opacity-100 {
  opacity: 1;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

body[data-slug="services"] nav.main-menu ul li a[href="/services.html"]:after, body[data-slug="services"] nav.main-menu ul li a[href="/services"]:after {
  width: calc(100% - 4rem);
  left: .75rem;
}

body[data-slug="services"] .hero {
  display: none;
}

body[data-slug="services"] .hero h1 {
  margin-bottom: 0;
}

body[data-slug="services"] .hero:after {
  content: "Services";
}

body[data-slug="services"] #topic-modal a[href*="services"]:not(.btn) {
  pointer-events: none;
  cursor: text;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

body[data-slug="services"] #topic-modal .btn[href*="services"] {
  display: none;
}

body[data-slug="services"] .well {
  background-image: url("https://presentingsolutions.com/sitefiles/services-bgd.jpg");
  padding-top: 15rem;
}

body[data-slug="services"] .well .container {
  text-align: center;
}

body[data-slug="services"] .well .container h3 {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
  line-height: 1.5;
}

body[data-slug="services"] .well .container h3 span {
  margin-bottom: 1.5rem;
  display: block;
}

body[data-slug="services"] .types {
  background-image: url("https://presentingsolutions.com/sitefiles/peeking-p-1.png");
  background-position: 0 -5rem;
  background-repeat: no-repeat;
  background-size: 60%;
  padding-top: 8rem;
  padding-bottom: 4rem;
  font-size: 22px;
  line-height: 30px;
}

body[data-slug="services"] .types article {
  text-align: center;
  background-size: 25%;
  padding-left: 2rem;
  padding-right: 2rem;
}

body[data-slug="services"] .types article:first-child {
  padding-bottom: 6rem;
}

body[data-slug="services"] .types article h2, body[data-slug="services"] .types article .prompt, body[data-slug="services"] .types article figure {
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
}

body[data-slug="services"] .types article h2 {
  padding-top: 6rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
  position: relative;
}

body[data-slug="services"] .types article h2:before {
  width: 5rem;
  height: 100%;
  content: " ";
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: -9rem;
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

body[data-slug="services"] .types article .prompt {
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
}

body[data-slug="services"] .types article ol {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
}

body[data-slug="services"] .types article ol li:before, body[data-slug="services"] .types article ol li:after, body[data-slug="services"] .types article ol li span:before, body[data-slug="services"] .types article ol li span:after {
  height: 2em;
}

body[data-slug="services"] .types article button, body[data-slug="services"] .types article a.btn {
  margin-top: 1.5rem;
  margin-bottom: 0;
  font-size: 22px;
  line-height: 30px;
  display: inline-block;
}

body[data-slug="services"] .types article figure {
  max-width: 28rem;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(66 115 163 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: .3;
  border-radius: .375rem;
  margin: 1.5rem auto 2.5rem;
  padding: 2rem 3rem 1.5rem;
  display: block;
  position: relative;
  overflow: hidden;
}

body[data-slug="services"] .types article figure:before {
  opacity: .04;
  content: "i";
  font-size: 18rem;
  line-height: 0;
  display: block;
  position: absolute;
  bottom: -.75rem;
  left: 0;
}

body[data-slug="services"] .types article figure figcaption {
  margin-bottom: 1rem;
  font-size: 16px;
  font-style: italic;
  line-height: 20px;
}

body[data-slug="services"] .types article:first-child {
  --tw-border-opacity: 1;
  border-right-width: 1px;
  border-right-color: rgb(66 115 163 / var(--tw-border-opacity));
}

body[data-slug="services"] .types article.workshops h2:before {
  background-image: url("https://presentingsolutions.com/sitefiles/icon-workshop.png");
}

body[data-slug="services"] .types article.team h2:before {
  background-image: url("https://presentingsolutions.com/sitefiles/icon-team-training.png");
}

body[data-slug="services"] .not-sure {
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
  background-image: url("https://presentingsolutions.com/sitefiles/p-wave-motion.png");
  background-position: 0 -5rem;
  background-repeat: no-repeat;
  background-size: 100%;
  padding-top: 5rem;
  padding-bottom: 2rem;
}

body[data-slug="services"] .not-sure .container {
  max-width: 72rem;
}

body[data-slug="services"] .not-sure h4 {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
}

body[data-slug="services"] .not-sure h5 {
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-size: 1.875rem;
  line-height: 2;
}

body[data-slug="services"] .not-sure p {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 1.875rem;
  line-height: 2.25rem;
}

body[data-slug="services"] .not-sure button, body[data-slug="services"] .not-sure a.btn {
  margin-top: 1.5rem;
  margin-bottom: 0;
  font-size: 22px;
  line-height: 30px;
  display: inline-block;
}

body[data-slug="services"] .additional-services h3 {
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  font-family: cera_pro, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 2.25rem;
  line-height: 2.5rem;
}

body[data-slug="services"] .additional-services p {
  max-width: 48rem;
  font-size: 22px;
  line-height: 30px;
}

body[data-slug="services"] .additional-services ul {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1.5rem;
  margin-bottom: 2.5rem;
  font-size: 22px;
  line-height: 30px;
}

@media (min-width: 768px) {
  body[data-slug="services"] .additional-services ul {
    display: grid;
  }
}

body[data-slug="services"] .additional-services ul li {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

body[data-slug="services"] .additional-services ul li input[type="checkbox"] {
  display: none;
}

body[data-slug="services"] .additional-services ul li label {
  height: 100%;
  width: 100%;
  cursor: pointer;
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(66 115 163 / var(--tw-border-opacity));
  border-radius: .5rem;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem 1rem 1.25rem 5rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: relative;
}

body[data-slug="services"] .additional-services ul li label:before {
  height: 2.5rem;
  width: 2.5rem;
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0px 0px 15px #15406a59 inset;
  --tw-shadow-colored: inset 0px 0px 15px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  content: " ";
  border-radius: .375rem;
  position: absolute;
  top: 50%;
  left: 1.25rem;
  transform: translateY(-50%);
}

body[data-slug="services"] .additional-services ul li label:after {
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
  opacity: 0;
  content: "✔";
  font-size: 3rem;
  line-height: 1;
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 50%;
  left: 1.75rem;
  transform: translateY(-50%);
}

body[data-slug="services"] .additional-services ul li label:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(66 115 163 / var(--tw-bg-opacity));
  --tw-bg-opacity: .1;
}

body[data-slug="services"] .additional-services ul li input[type="checkbox"]:checked + label {
  --tw-bg-opacity: 1;
  background-color: rgb(66 115 163 / var(--tw-bg-opacity));
  --tw-bg-opacity: .5;
}

body[data-slug="services"] .additional-services ul li input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

body[data-slug="services"] .additional-services ul li strong {
  display: block;
}

body[data-slug="services"] .additional-services .form-wrapper {
  width: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  body[data-slug="services"] .additional-services .form-wrapper {
    width: 48rem;
  }

  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .md\:ml-16 {
    margin-left: 4rem;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:ml-12 {
    margin-left: 3rem;
  }

  .md\:mr-10 {
    margin-right: 2.5rem;
  }

  .md\:ml-8 {
    margin-left: 2rem;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:w-3\/5 {
    width: 60%;
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:text-left {
    text-align: left;
  }
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

body[data-slug="about"] nav.main-menu ul li a[href="/about.html"]:after, body[data-slug="about"] nav.main-menu ul li a[href="/about"]:after {
  width: calc(100% - 4rem);
  left: .75rem;
}

body[data-slug="about"] .hero {
  display: none;
}

body[data-slug="about"] .hero:after {
  content: "About Us";
}

body[data-slug="about"] .well {
  background-image: url("https://presentingsolutions.com/sitefiles/about-bgd.jpg");
  padding-top: 15rem;
}

body[data-slug="about"] .we-connect {
  background-image: url("https://presentingsolutions.com/sitefiles/p-wave-balance.png");
  background-position: 50% -5rem;
  background-repeat: no-repeat;
  background-size: 100%;
  overflow-y: hidden;
}

body[data-slug="about"] .we-connect .container {
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

body[data-slug="about"] .we-connect .we-connect-header {
  flex-direction: row;
  align-items: flex-start;
}

@media (min-width: 768px) {
  body[data-slug="about"] .we-connect .we-connect-header {
    display: flex;
  }
}

body[data-slug="about"] .we-connect .we-connect-header {
  height: calc(.75rem + 80px);
}

body[data-slug="about"] .we-connect .we-connect-header h4 {
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 36px;
  line-height: 1;
}

body[data-slug="about"] .we-connect .we-connect-header .before, body[data-slug="about"] .we-connect .we-connect-header .after {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-color: rgb(21 64 106 / var(--tw-border-opacity));
  display: none;
}

@media (min-width: 768px) {
  body[data-slug="about"] .we-connect .we-connect-header .before, body[data-slug="about"] .we-connect .we-connect-header .after {
    display: inline-block;
  }
}

body[data-slug="about"] .we-connect .we-connect-header .before, body[data-slug="about"] .we-connect .we-connect-header .after {
  content: " ";
  width: 40px;
  height: 0;
  opacity: 0;
  margin-top: .75rem;
}

body[data-slug="about"] .we-connect .we-connect-header .before {
  border-left-width: 1px;
  transition: height 2s ease-out, opacity .2s;
}

body[data-slug="about"] .we-connect .we-connect-header .after {
  border-right-width: 1px;
  transition: height 2s ease-out .75s, opacity .2s .75s;
}

body[data-slug="about"] .we-connect .we-connect-header.active .before, body[data-slug="about"] .we-connect .we-connect-header.active .after {
  height: 80px;
  opacity: 1;
}

body[data-slug="about"] .we-connect .we-connect-how {
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
}

body[data-slug="about"] .we-connect ul {
  justify-content: space-between;
  margin-top: 1rem;
  display: flex;
}

body[data-slug="about"] .we-connect ul li.how-we-connect {
  text-transform: uppercase;
  letter-spacing: -.025em;
  font-family: cera_pro, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 28px;
  line-height: 32px;
}

@media (min-width: 768px) {
  body[data-slug="about"] .we-connect ul li.how-we-connect:first-child {
    margin-left: -4rem;
  }

  body[data-slug="about"] .we-connect ul li.how-we-connect:last-child {
    margin-right: -4rem;
  }
}

body[data-slug="about"] .letter {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(215 218 221 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: .375rem;
  padding: 2rem;
  position: relative;
}

@media (min-width: 768px) {
  body[data-slug="about"] .letter {
    --tw-rotate: -3deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    margin-top: 4rem;
    margin-bottom: 8rem;
    padding: 4rem 6rem 6rem;
  }
}

body[data-slug="about"] .letter:after {
  pointer-events: none;
  z-index: 0;
  height: 100%;
  width: 100%;
  content: " ";
  background: url("https://presentingsolutions.com/sitefiles/paper-texture.jpg") 0 0 / cover no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}

body[data-slug="about"] .letter:before {
  content: "";
  width: 50%;
  z-index: -1;
  position: absolute;
  inset: 20px 40px 60% auto;
  transform: rotate(-3deg);
  box-shadow: 0 -15px 50px #0003;
}

body[data-slug="about"] .letter p, body[data-slug="about"] .letter ul {
  z-index: 10;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  position: relative;
}

body[data-slug="about"] .letter p:last-child {
  margin-bottom: 0;
}

body[data-slug="about"] .letter .signature {
  width: 12rem;
}

body[data-slug="about"] .instructor-lists {
  margin-left: auto;
  margin-right: auto;
}

body[data-slug="about"] .instructor-lists ul {
  padding-left: 1.25rem;
  list-style-type: disc;
}

body[data-slug="about"] .instructor-lists ul br {
  display: none;
}

body[data-slug="about"] .instructor-lists .primary-instructors {
  margin-bottom: 6rem;
}

body[data-slug="about"] .instructor-lists .primary-instructors > li {
  justify-content: center;
  display: flex;
}

body[data-slug="about"] .instructor-lists .primary-instructors a {
  max-width: 28rem;
}

body[data-slug="about"] .instructor-lists .primary-instructors a img, body[data-slug="about"] .instructor-lists .primary-instructors a div.info-panel {
  transform-style: preserve-3d;
  transition: all .6s;
}

body[data-slug="about"] .instructor-lists .primary-instructors a img.faded {
  opacity: 0 !important;
}

body[data-slug="about"] .instructor-lists .primary-instructors a img.full {
  opacity: 1 !important;
}

body[data-slug="about"] .instructor-lists .primary-instructors a.active img, body[data-slug="about"] .instructor-lists .primary-instructors a:hover img {
  transform: rotateY(180deg);
}

body[data-slug="about"] .instructor-lists .primary-instructors a.active div.info-panel, body[data-slug="about"] .instructor-lists .primary-instructors a:hover div.info-panel {
  transform: rotateY(0);
}

body[data-slug="about"] .instructor-lists .primary-instructors img, body[data-slug="about"] .instructor-lists .primary-instructors div.info-panel {
  backface-visibility: hidden;
}

body[data-slug="about"] .instructor-lists .primary-instructors strong {
  font-size: 36px;
  line-height: 36px;
}

body[data-slug="about"] .instructor-lists .primary-instructors div.info-panel {
  z-index: 10;
  height: 100%;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: .9;
  border-radius: 9999px;
  align-items: center;
  padding: 4rem;
  display: flex;
  position: absolute;
  top: 0;
  transform: rotateY(180deg);
}

body[data-slug="about"] .instructor-lists .primary-instructors div.info-panel ul {
  white-space: normal;
  font-size: 16px;
  line-height: 20px;
}

body[data-slug="about"] .instructor-lists .primary-instructors div.info-panel ul li {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

body[data-slug="about"] .bio {
  margin-top: 4rem;
  font-size: 16px;
  line-height: 20px;
}

body[data-slug="about"] .bio h1, body[data-slug="about"] .bio h2, body[data-slug="about"] .bio h3, body[data-slug="about"] .bio h4, body[data-slug="about"] .bio h5 {
  text-transform: uppercase;
  letter-spacing: -.025em;
  margin-bottom: 1.5rem;
  font-family: cera_pro, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 2.25rem;
  line-height: 2.5rem;
}

body[data-slug="about"] .bio img {
  float: left;
  width: 25%;
  margin-bottom: 1.25rem;
  margin-right: 1.25rem;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

body[data-slug="contact"] nav.main-menu ul li a[href="/contact.html"]:after, body[data-slug="contact"] nav.main-menu ul li a[href="/contact"]:after {
  width: calc(100% - 4rem);
  left: .75rem;
}

body[data-slug="contact"] .hero {
  min-height: min-content;
}

body[data-slug="contact"] .hero .container {
  padding-top: 12rem;
  padding-bottom: 8rem;
}

body[data-slug="contact"] .hero .container h2 {
  text-align: left;
}

body[data-slug="contact"] .hero:after {
  content: "Contact Us";
}

body[data-slug="contact"] form.container {
  max-width: 56rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;
  padding-bottom: 2.5rem;
  display: flex;
  position: relative;
}

body[data-slug="contact"] form.container div {
  width: 100%;
}

body[data-slug="contact"] form.container div div {
  width: auto;
}

body[data-slug="contact"] form.container .col-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1.5rem;
  display: grid;
}

body[data-slug="contact"] form.container input, body[data-slug="contact"] form.container textarea {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(66 115 163 / var(--tw-border-opacity));
  --tw-border-opacity: .4;
  --tw-shadow: 0px 0px 8px #15406a59 inset;
  --tw-shadow-colored: inset 0px 0px 8px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-width: 0;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

body[data-slug="contact"] form.container input:focus, body[data-slug="contact"] form.container textarea:focus {
  --tw-border-opacity: 1;
}

body[data-slug="contact"] .request {
  --tw-bg-opacity: 1;
  background-color: rgb(240 243 246 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
  padding-top: 5rem;
  padding-bottom: 5rem;
}

body[data-slug="contact"] .request .container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

body[data-slug="contact"] .request .container h3 {
  text-transform: uppercase;
  margin-bottom: 2.5rem;
  font-family: cera_pro, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 2.25rem;
  line-height: 2.5rem;
}

body[data-slug="contact"] .request .container p {
  margin-bottom: 3rem;
  font-size: 22px;
  line-height: 30px;
}

body#public {
  z-index: 30;
  --tw-bg-opacity: 1;
  background-image: url("https://presentingsolutions.com/sitefiles/peeking-p-1.png");
  background-position: 0 -5rem;
  background-repeat: no-repeat;
  background-size: 60%;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0px 20px 30px #15406a29;
  --tw-shadow-colored: 0px 20px 30px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-bottom-left-radius: 26px;
  border-bottom-right-radius: 26px;
  margin-top: 1rem;
  padding: 0 3rem 5rem;
  position: relative;
}

body#public .wufoo label, body#public .wufoo legend {
  font-weight: 400;
  font-family: adobe-caslon-pro !important;
  font-size: 16px !important;
  line-height: 20px !important;
}

body#public .wufoo legend {
  vertical-align: top;
  display: flex;
}

body#public .wufoo legend .req {
  margin-top: 0;
  margin-left: .25rem;
}

body#public .wufoo li.focused {
  background-color: #0000 !important;
}

body#public .wufoo label.desc, body#public .wufoo legend.desc {
  margin-bottom: .5rem;
  font-family: adobe-caslon-pro;
  font-weight: 400;
  --tw-text-opacity: 1 !important;
  color: rgb(21 64 106 / var(--tw-text-opacity)) !important;
  font-size: 22px !important;
  line-height: 30px !important;
}

body#public .wufoo legend.desc, body#public h1, body#public h2, body#public h3, body#public h4, body#public h5 {
  margin-bottom: .5rem !important;
}

body#public h1, body#public h2, body#public h3, body#public h4, body#public h5 {
  letter-spacing: -.025em;
  --tw-text-opacity: 1 !important;
  color: rgb(21 64 106 / var(--tw-text-opacity)) !important;
  font-family: cera_pro, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji !important;
  font-size: 28px !important;
  font-weight: 500 !important;
  line-height: 1.25 !important;
}

body#public h1#logo {
  display: none;
}

body#public header#header {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

body#public header#header h1, body#public header#header h2, body#public header#header h3, body#public header#header h4, body#public header#header h5 {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
  align-self: stretch;
  margin-bottom: 1rem;
  font-family: cera_pro, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 36px;
  font-weight: 500;
  line-height: 1.25;
}

body#public header#header p, body#public header#header div {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
  align-self: stretch;
  margin-bottom: 1rem;
  font-family: adobe-caslon-pro;
  font-weight: 500;
  line-height: 1.25;
  font-size: 16px !important;
  line-height: 20px !important;
}

body#public input[type="text"], body#public input[type="email"], body#public input[type="tel"], body#public textarea {
  max-width: 32rem;
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(66 115 163 / var(--tw-border-opacity));
  --tw-border-opacity: .4;
  --tw-shadow: 0px 0px 8px #15406a59 inset;
  --tw-shadow-colored: inset 0px 0px 8px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .375rem;
  outline-width: 0;
  margin-bottom: 1.5rem;
  margin-left: 0;
  padding: .25rem .5rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

body#public input[type="text"]:focus, body#public input[type="email"]:focus, body#public input[type="tel"]:focus, body#public textarea:focus {
  --tw-border-opacity: 1;
}

body#public li span {
  flex-direction: column-reverse;
  display: flex;
}

body#public li span label {
  margin-bottom: .25rem;
  margin-left: 0;
}

body#public .section {
  margin-bottom: 2rem !important;
}

body#public .section div {
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
  margin-top: .5rem;
  margin-bottom: 1.5rem;
  font-weight: 500;
  line-height: 1.25;
}

body#public .req {
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
  margin-top: .5rem;
  margin-bottom: 1rem;
  font-size: 22px;
  line-height: 30px;
}

body#public .col {
  display: flex;
}

body#public input[type="checkbox"], body#public input[type="radio"] {
  display: none;
}

body#public input[type="checkbox"] + label, body#public input[type="radio"] + label {
  height: 3.5rem;
  width: 100%;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  margin-right: 1rem;
  padding-left: 2.25rem;
  padding-right: 1rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: relative;
}

body#public input[type="checkbox"] + label:before, body#public input[type="radio"] + label:before {
  height: 1.5rem;
  width: 1.5rem;
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(66 115 163 / var(--tw-border-opacity));
  --tw-border-opacity: .4;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0px 0px 8px #15406a59 inset;
  --tw-shadow-colored: inset 0px 0px 8px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 9999px;
  position: absolute;
  top: 50%;
  left: 0;
}

body#public input[type="checkbox"] + label:before:focus, body#public input[type="radio"] + label:before:focus {
  --tw-border-opacity: 1;
}

body#public input[type="checkbox"] + label:before, body#public input[type="radio"] + label:before {
  content: " ";
  transform: translateY(-50%);
}

body#public input[type="checkbox"] + label:after, body#public input[type="radio"] + label:after {
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
  opacity: 0;
  font-size: 1.875rem;
  line-height: 2.25rem;
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 50%;
  left: .25rem;
  transform: translateY(-50%);
}

body#public input[type="checkbox"] + label:before {
  border-radius: .375rem;
}

body#public input[type="checkbox"] + label:after {
  content: "✔";
}

body#public input[type="radio"] + label:after {
  content: "•";
  margin-top: .05rem;
  margin-left: 0;
  font-size: 3rem;
  line-height: 1;
}

body#public input[type="checkbox"]:checked + label:after, body#public input[type="radio"]:checked + label:after {
  opacity: 1;
}

body#public .buttons {
  justify-content: center;
  display: flex;
}

body#public input[type="submit"] {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(215 218 221 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  letter-spacing: -.025em;
  --tw-text-opacity: 1;
  color: rgb(21 64 106 / var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(21 64 106 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-width: 0;
  border-radius: .375rem;
  padding: .75rem 2.5rem;
  font-family: cera_pro, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 16px;
  line-height: 20px;
  transition: all .4s cubic-bezier(.67, .075, .34, 1.65);
  box-shadow: 0 3px 15px #15406a26;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

body[data-slug="thankyou"] nav.main-menu ul li a[href="/contact.html"]:after, body[data-slug="thankyou"] nav.main-menu ul li a[href="/contact"]:after {
  width: calc(100% - 4rem);
  left: .75rem;
}

body[data-slug="thankyou"] nav.main-menu [data-pop-proposal] {
  display: none;
}

body[data-slug="thankyou"] .hero {
  min-height: min-content;
}

body[data-slug="thankyou"] .hero .container {
  padding-top: 12rem;
  padding-bottom: 8rem;
}

body[data-slug="thankyou"] .hero .container h2 {
  text-align: left;
}

body[data-slug="thankyou"] .hero:after {
  content: "Thank You";
}

html, body {
  min-height: 100vh;
}

body {
  flex-direction: column;
  display: flex;
}

#topic-modal h1, #topic-modal h2, #topic-modal h3, #topic-modal h4, #topic-modal h5 {
  margin-bottom: 1rem;
}

#topic-modal .bullets.columns-2, #topic-modal .bullets.columns-4 {
  column-count: 1;
  min-height: 100%;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
}

#topic-modal .bullets.columns-2 li, #topic-modal .bullets.columns-4 li {
  display: block;
}

#proposal-modal {
  pointer-events: auto;
  overflow-y: auto;
}

#proposal-modal .modal-dialog {
  padding-bottom: 3rem;
}

#proposal-modal .modal-dialog .modal-content {
  max-height: max-content;
  border-radius: .5rem;
  padding: 0 0 2rem;
}

#proposal-modal .modal-dialog .modal-content iframe {
  height: 116rem;
}

.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner:before, .hamburger.is-active .hamburger-inner:after, .hamburger-inner, .hamburger-inner:before, .hamburger-inner:after {
  --tw-bg-opacity: 1;
  background-color: rgb(21 64 106 / var(--tw-bg-opacity));
}

.footer-form {
  background: url("https://presentingsolutions.com/sitefiles/diagonal-stripe.png") 0 0 / 16%;
  position: relative;
}

.footer-form:before {
  pointer-events: none;
  z-index: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  --tw-gradient-from: transparent;
  --tw-gradient-to: #0000;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #15406a;
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.footer-form .container {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  display: flex;
  position: relative;
}

.footer-form .container .form-wrapper h6 {
  font-size: 22px;
  line-height: 30px;
}

.footer-form .container .form-wrapper form {
  margin-top: 2.5rem;
}

body > footer {
  --tw-border-opacity: 1;
  border-top-width: 8px;
  border-top-color: rgb(66 115 163 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  background: url("https://presentingsolutions.com/sitefiles/diagonal-stripe.png") 0 0 / 16%;
  margin-top: auto;
  position: relative;
}

body > footer:before {
  pointer-events: none;
  z-index: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  --tw-gradient-from: transparent;
  --tw-gradient-to: #0000;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #15406a;
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

body > footer .container {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2.5rem;
  padding-bottom: 1.25rem;
  display: flex;
  position: relative;
}

body > footer .container img {
  width: 10rem;
  margin-bottom: 1.25rem;
  display: block;
}

body > footer .container ul.cities {
  margin-bottom: 1.5rem;
  font-size: 12px;
  line-height: 20px;
  display: flex;
}

body > footer .container ul.cities li {
  padding: 1.25rem .75rem .5rem;
  display: inline-block;
  position: relative;
}

body > footer .container ul.cities li:before {
  pointer-events: none;
  content: "•";
  margin-left: -.75rem;
  display: inline-block;
  position: absolute;
}

body > footer .container ul.cities li:first-child:before {
  content: "";
}

body > footer .container a {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  display: block;
}

body > footer .container a[href^="tel"] {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

body > footer .container p.copyright {
  margin-top: 1.5rem;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
}

/*# sourceMappingURL=about.a96e44ac.css.map */
