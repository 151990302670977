ol {
  li {
    @apply m-6 relative;

    &:before,
    &:after,
    span:before,
    span:after {
      @apply text-blue flex flex-col justify-center absolute text-right left-0 w-6 text-base;
      height: 2.25rem;
    }
    &:before {
      @apply -ml-8 top-0;
    }
    span:before {
      @apply -ml-10 top-0;
      left: -0.05rem;
    }
    &:after {
      @apply -ml-12 top-0;
      left: -0.15rem;
    }
    span:after {
      @apply -ml-10 top-0;
      left: -0.05rem;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      &:before {
        content: "\2022";
      }
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      span:before {
        content: "\2022";
      }
    }
    &:nth-child(3),
    &:nth-child(4) {
      &:after {
        content: "\2022";
      }
    }
    &:nth-child(4) {
      span:after {
        content: "\2022";
      }
      &:before,
      span:before {
        @apply -top-1;
      }
      &:after {
        @apply -ml-8 left-0;
      }
      &:after,
      span:after {
        @apply top-1;
      }
    }
  }
}
ul.bullets {
  li {
    @apply ml-6 leading-tight relative;
    margin-bottom: 1rem;
    &:before {
      @apply text-blue flex flex-col justify-center absolute text-right left-0 w-6 text-base -ml-10 top-0 h-full;
      content: "\2022";
    }
  }
}
