@tailwind components;
@tailwind utilities;

@layer components {
  .hero {
    @apply overflow-hidden relative bg-gradient-to-br from-white via-white to-blue-light rounded-b-xl shadow-blue-lg-light-bottom min-h-min flex flex-col justify-center;
    .container {
      @apply text-blue flex flex-col justify-center pb-32 pt-52 relative md:min-h-min w-content; /* max-w-4xl; items-center */

      /*   h1 {
        @apply font-sans tracking-tight uppercase text-5xl mb-20 text-center;
      }
      h2 {
        @apply text-4xl; 
        line-height: 2;
      }*/
      h1,
      h2 {
        @apply font-serif normal-case text-left text-5xl leading-normal inline-block; /* text-4xl;*/
      }
      h1 {
        @apply mb-32;
      }
      button {
        @apply mt-20 -mb-5 self-center text-base;
      }
      * {
        @apply z-10 relative;
      }
    }
    &:after {
      @apply font-sans tracking-tight uppercase absolute block pointer-events-none opacity-3 whitespace-nowrap text-blue;
      font-size: 400px;
      line-height: 1;
      bottom: -0.2em;
      left: -0.2em;
      z-index: 0;
      filter: blur(6px);
    }
  }
}
