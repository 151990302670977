@tailwind components;
@tailwind utilities;

body.modal-open {
  @apply overflow-hidden;
  padding-right: 15px;
  nav.main-menu {
    padding-right: 15px;
  }
}
.modal-backdrop {
  @apply opacity-0 fixed top-0 left-0 w-screen h-screen;
  z-index: 1050;
  background-color: #000;

  &.show {
    @apply opacity-50 pointer-events-auto;
  }
}
.modal {
  @apply fixed top-0 left-0 w-screen h-screen outline-none overflow-x-hidden overflow-y-hidden opacity-0 pointer-events-none hidden;
  z-index: 1051;
  
  .modal-dialog {
    @apply left-1/2 relative w-screen md:w-auto overflow-y-hidden pointer-events-none rounded-lg;
    width: 600px;
    max-width: 100%;
    transform: translateX(-50%);
    top: 3rem;
    bottom: 3rem;

    .modal-content {
      @apply border-none shadow-xl relative flex flex-col w-full pointer-events-none overflow-y-auto bg-white bg-clip-padding outline-none text-current p-4 md:py-12 md:px-16;
      max-height: calc(100vh - 6rem);
    }
    .modal-header {
      h1,
      h2,
      h3,
      h4,
      h5 {
        @apply self-stretch text-center font-sans leading-tight text-xl font-medium text-blue mb-8;
      }
      button[data-dismiss="modal"] {
        @apply absolute top-2 right-2 box-content w-8 h-8 text-black border-none rounded-none opacity-30 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline flex items-center justify-center;
        &:after {
          @apply block text-5xl h-8;
          content: "×";
        }
      }
    }

    .modal-footer {
    }
  }


  &.show {
    @apply opacity-100 block;
    .modal-dialog {
      @apply pointer-events-auto;
      .modal-content {
        @apply pointer-events-auto;
      }
    }
  }
}

@layer components {
  .modal-centered .modal-dialog {
      @apply top-1/2;
      transform: translateY(-50%);
  }
 .modal-full .modal-dialog {
      @apply w-screen;
      max-width: 100vw;
  }
 .modal-lg .modal-dialog {
      max-width: 1140px;
      width: 1140px;
  }

 .modal-xl .modal-dialog {
      max-width: 1380px;
      width: 1380px;
  }
}