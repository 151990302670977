body#public {
  @apply mt-4 pt-0 px-12 bg-white bg-peek rounded-b-xl shadow-blue-lg-light-bottom pb-20 relative z-30;
  .wufoo {
    label,
    legend {
      @apply !font-serif !text-sm font-normal;
    }
    legend {
      @apply flex align-top;
      .req {
        @apply -mt-0 ml-1;
      }
    }
    li.focused {
      @apply !bg-transparent;
    }
  }
  .wufoo {
    label.desc,
    legend.desc {
      @apply !text-blue font-serif !text-base font-normal mb-2;
    }
    legend.desc {
      @apply !mb-2;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    @apply !font-sans !leading-tight tracking-tight !text-lg !font-medium !text-blue !mb-2;
  }

  h1#logo {
    display: none;
  }

  header#header {
    @apply py-4;
    h1,
    h2,
    h3,
    h4,
    h5 {
      @apply self-stretch text-center font-sans leading-tight text-xl font-medium text-blue mb-4;
    }
    p,
    div {
      @apply self-stretch text-center leading-tight font-medium text-blue mb-4 font-serif !text-sm;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    @apply py-1 px-2 outline-0 rounded-md transition-all border-2 border-tuftsy border-opacity-40 focus:border-opacity-100 shadow-blue-straight-in-sm mb-6 ml-0 max-w-lg;
  }
  li {
    span {
      @apply flex flex-col-reverse;
      label {
        @apply mb-1 ml-0;
      }
    }
  }
  .section {
    @apply !mb-8;
    div {
      @apply leading-tight font-medium text-blue mt-2 mb-6;
    }
  }

  .req {
    @apply text-blue text-base mt-2 mb-4;
  }

  .col {
    @apply flex;
  }
  input[type="checkbox"],
  input[type="radio"] {
    @apply hidden;

    & + label {
      @apply pl-9 pr-4 w-full h-14 relative cursor-pointer transition-all flex flex-col justify-center mr-4;

      &:before {
        @apply absolute bg-white rounded-full left-0 top-1/2 w-6 h-6 shadow-blue-straight-in-sm border-2 border-tuftsy border-opacity-40 focus:border-opacity-100;
        content: " ";
        transform: translateY(-50%);
      }

      &:after {
        @apply absolute text-blue text-3xl left-1 top-1/2 transition-opacity opacity-0;
        transform: translateY(-50%);
      }
    }
  }

  input[type="checkbox"] + label {
    &:before {
      @apply rounded-md;
    }

    &:after {
      content: "✔";
    }
  }

  input[type="radio"] + label {
    &:after {
      @apply text-5xl;
      content: "•";
      margin-top: 0.05rem;
      margin-left: 0;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    &:checked + label {
      &:after {
        @apply opacity-100;
      }
    }
  }

  .buttons {
    @apply flex justify-center;
  }
  input[type="submit"] {
    @apply text-sm rounded-md p-3 px-10 bg-white border border-silver text-blue font-sans tracking-tight;
    transition: all 400ms cubic-bezier(0.67, 0.075, 0.34, 1.65);
    box-shadow: 0px 3px 15px rgba(21, 64, 106, 0.15);

    @apply border-0 bg-blue text-white;
  }
}
