@tailwind base;

@layer base {
  html {
    font-family: adobe-caslon-pro, "Georgia", "Times New Roman", times, serif;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    ul,
    ol {
      line-height: 2em;
    }
  }

  a:not(.btn),
  button {
    @apply cursor-pointer;
    /*  Hey, if you're tearing your hair out because this isn't working… are you running Photoshop? 
        Because: https://stackoverflow.com/questions/18434626/why-is-cursorpointer-effect-in-css-not-working/35212344#35212344
        And: https://old.reddit.com/r/programminghorror/comments/8yvelm/why_is_cursorpointer_effect_in_css_not_working/
        Don't be like me, kids!
        -GK
    */
  }
  a {
    @apply text-blue md:whitespace-nowrap;
  }

  article,
  div,
  p,
  section,
  main {
    a:not(.btn) {
      text-decoration: underline;
    }
  }

  p {
    margin-bottom: 2rem;
  }
  .serif {
  }
}
