@tailwind components;
@tailwind utilities;

body[data-slug="services"] {
  nav.main-menu ul li a[href="/services.html"],
  nav.main-menu ul li a[href="/services"] {
    &:after {
      width: calc(100% - 4rem);
      @apply left-3;
    }
  }

  .hero {
    @apply hidden;
    h1 {
      @apply mb-0;
    }
    &:after {
      content: "Services";
    }
  }

  #topic-modal {
    a[href*="services"]:not(.btn) {
      @apply text-black pointer-events-none cursor-text no-underline;
    }
    .btn[href*="services"] {
      @apply hidden;
    }
  }
  .well {
    padding-top: 15rem;
    background-image: url("https://presentingsolutions.com/sitefiles/services-bgd.jpg");

    .container {
      @apply text-center;
      h3 {
        @apply pt-10 pb-8;
        line-height: 1.5;
        span {
          @apply block mb-6;
        }
      }
    }
  }

  .types {
    @apply bg-peek pt-32 pb-16 bg-3/5 text-base;

    article {
      @apply text-center px-8 bg-1/4;

      &:first-child {
        @apply pb-24;
      }

      h2,
      .prompt,
      figure {
        @apply text-blue;
      }

      h2 {
        @apply text-4xl pt-24 relative;

        &:before {
          @apply w-20 absolute -mt-36 block left-1/2;
          height: 100%;
          content: " ";
          transform: translateX(-50%);
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }

      .prompt {
        @apply text-base font-bold mt-10 mb-6;
      }

      ol {
        @apply text-left inline-block mx-auto;

        li:before,
        li:after,
        li span:before,
        li span:after {
          height: 2em;
        }
      }

      button,
      a.btn {
        @apply inline-block text-base mt-6 mb-0;
      }

      figure {
        @apply max-w-md mt-6 mb-10 border border-tuftsy bg-white bg-opacity-30 rounded-md overflow-hidden block mx-auto pt-8 px-12 pb-6 relative;

        &:before {
          @apply block absolute opacity-4 left-0 -bottom-3;
          content: "i";
          font-size: 18rem;
          line-height: 0;
        }

        figcaption {
          @apply italic text-sm mb-4;
        }
      }

      &:first-child {
        @apply border-r border-r-tuftsy;
      }

      &.workshops {
        h2:before {
          background-image: url("https://presentingsolutions.com/sitefiles/icon-workshop.png");
        }
      }

      &.team {
        h2:before {
          background-image: url("https://presentingsolutions.com/sitefiles/icon-team-training.png");
        }
      }
    }
  }

  .not-sure {
    @apply bg-wave-motion pt-20 pb-8 text-blue;

    .container {
      @apply max-w-6xl;
    }

    h4 {
      @apply text-center text-4xl mb-12;
    }
    h5 {
      @apply text-3xl my-12;
      line-height: 2;
    }

    p {
      @apply text-3xl text-center mb-12;
    }
    button,
    a.btn {
      @apply inline-block text-base mt-6 mb-0;
    }
  }

  .additional-services {
    h3 {
      @apply text-white font-sans uppercase text-4xl my-10;
    }
    p {
      @apply max-w-3xl text-base;
    }
    ul {
      @apply md:grid grid-cols-2 gap-6 text-base mb-10;

      li {
        @apply flex items-center justify-center relative;

        /* 
          border & bgd: label
          checkbox: label :before
          checkmark: label :after?
        */

        input[type="checkbox"] {
          @apply hidden;
        }

        label {
          @apply pl-20 pt-6 pr-4 pb-5 w-full h-full relative rounded-lg border-2 border-tuftsy cursor-pointer transition-all flex flex-col justify-center;

          &:before {
            @apply absolute bg-white border-2 border-white rounded-md left-5 top-1/2 w-10 h-10 shadow-blue-straight-in;
            content: " ";
            transform: translateY(-50%);
          }

          &:after {
            @apply absolute text-blue text-5xl left-7 top-1/2 transition-opacity opacity-0;
            content: "✔";
            transform: translateY(-50%);
          }

          &:hover {
            @apply bg-tuftsy bg-opacity-10;
          }
        }

        input[type="checkbox"]:checked + label {
          @apply bg-tuftsy bg-opacity-50;

          &:after {
            @apply opacity-100;
          }
        }

        strong {
          @apply block;
        }
      }
    }
    .form-wrapper {
      @apply w-full md:w-3xl flex flex-col items-center;
    }
  }
}
