@tailwind components;

body[data-slug="about"] {
  nav.main-menu ul li a[href="/about.html"],
  nav.main-menu ul li a[href="/about"] {
    &:after {
      width: calc(100% - 4rem);
      @apply left-3;
    }
  }
  .hero {
    @apply hidden;
    &:after {
      content: "About Us";
    }
  }
  .well {
    padding-top: 15rem;
    background-image: url("https://presentingsolutions.com/sitefiles/about-bgd.jpg");
  }
  .we-connect {
    @apply bg-wave-balance overflow-y-hidden;
    .container {
      @apply flex flex-col items-center justify-center text-blue;
    }
    .we-connect-header {
      @apply md:flex flex-row items-start;
      height: calc(0.75rem + 80px);
      h4 {
        @apply text-xl px-4 text-center;
        line-height: 1;
      }
      .before,
      .after {
        @apply border-blue border-t hidden md:inline-block;
        content: " ";
        width: 40px;
        margin-top: 0.75rem;
        height: 0;
        opacity: 0;
      }
      .before {
        @apply border-l;
        transition: height 2s ease-out, opacity 0.2s ease;
      }
      .after {
        @apply border-r;
        transition: height 2s ease-out 0.75s, opacity 0.2s ease 0.75s;
      }
      &.active {
        .before,
        .after {
          height: 80px;
          opacity: 1;
        }
      }
    }
    .we-connect-how {
      @apply mx-auto inline-block;
    }
    ul {
      @apply flex justify-between mt-4;
      li.how-we-connect {
        @apply text-lg font-sans uppercase tracking-tight;

        &:first-child {
          @apply md:-ml-16;
        }

        &:last-child {
          @apply md:-mr-16;
          /*margin-right: -4.5rem;*/
        }
      }
    }
  }
  .letter {
    @apply p-8 md:pt-16 md:pb-24 md:px-24 md:mt-16 md:mb-32 border bg-white border-silver rounded-md relative md:-rotate-3;

    /* box-shadow: 50px -60px 40px rgba(0, 0, 0, 0.1);*/

    &:after {
      @apply absolute left-0 top-0 w-full h-full pointer-events-none z-0;
      content: " ";
      background: url("https://presentingsolutions.com/sitefiles/paper-texture.jpg")
        top left no-repeat;
      background-size: cover;
    }

    &:before {
      top: 20px;
      box-shadow: 0 -15px 50px rgba(0, 0, 0, 0.2);
      content: "";
      left: 10px;
      position: absolute;
      bottom: 60%;
      width: 50%;
      z-index: -1;
      left: auto;
      right: 40px;
      transform: rotate(-3deg);
    }

    p,
    ul {
      @apply relative text-black z-10;
    }
    p:last-child {
      @apply mb-0;
    }
    .signature {
      @apply w-48;
    }
  }
  .instructor-lists {
    @apply mx-auto;

    ul {
      @apply list-disc pl-5;
      br {
        display: none;
      }
    }

    .primary-instructors {
      @apply mb-24;
      > li {
        @apply flex justify-center;
      }
      a {
        @apply max-w-md;
        img,
        div.info-panel {
          transition: all 0.6s ease;
          transform-style: preserve-3d;
        }
        img {
          &.faded {
            opacity: 0 !important;
          }
          &.full {
            opacity: 1 !important;
          }
        }
      }
      a.active,
      a:hover {
        img {
          transform: rotateY(180deg);
        }
        div.info-panel {
          transform: rotateY(0);
        }
      }
      img,
      div.info-panel {
        backface-visibility: hidden;
      }
      strong {
        @apply text-xl;
      }
      div.info-panel {
        @apply z-10 top-0 absolute rounded-full h-full w-full p-16 flex items-center bg-white bg-opacity-90;
        transform: rotateY(180deg);
        ul {
          @apply whitespace-normal text-sm;
          li {
            @apply my-3;
          }
        }
      }
    }
  }
  .bio {
    @apply mt-16 text-sm;
    h1,
    h2,
    h3,
    h4,
    h5 {
      @apply font-sans tracking-tight uppercase text-4xl mb-6;
    }

    img {
      @apply w-1/4 float-left mr-5 mb-5;
    }
  }
}
