form {
  @apply flex flex-col items-center w-full relative;

  .warning {
    @apply text-red-600;
  }
  input[type="text"],
  input[type="email"],
  textarea {
    @apply block bg-white rounded-md w-full text-sm mb-8 px-4 pt-4 pb-2 border text-blue;

    &.invalid {
      @apply border-red-500 !important;
    }
  }

  &:after {
    @apply block absolute top-0 left-0 w-full h-full z-0 opacity-0 transition-all pointer-events-none;
    content: "";
  }

  &.bg-dark {
    input,
    textarea {
      @apply shadow-black-lg-straight;
    }
  }

  input,
  select,
  textarea,
  button {
    @apply transition-all;
  }

  .spinner {
    @apply absolute z-10 top-1/2 left-1/2 -ml-6 -mt-6 opacity-0 transition-all pointer-events-none;
  }

  .submitted-message {
    @apply opacity-0 absolute z-10 w-full text-center font-serif !text-lg text-blue transition-all  pointer-events-none flex flex-col items-center justify-center;
  }

  .errors {
    @apply text-red-700;
  }

  &.submitting,
  &.submitted {
    @apply pointer-events-none;
    input,
    select,
    textarea,
    button,
    ul {
      @apply opacity-10;
    }

    &:after {
      @apply opacity-100;
    }
  }
  &.submitting {
    .spinner {
      @apply opacity-100;
    }
  }
  &.submitted {
    .submitted-message {
      @apply opacity-100;
    }
  }
}
.footer-form form {
  .warning {
    @apply text-red-200;
  }
  .errors {
    @apply text-red-200;
  }
  .spinner {
    background: radial-gradient(farthest-side, #fff 94%, #0000) top/9px 9px
        no-repeat,
      conic-gradient(#0000 30%, #fff);
  }
  .submitted-message {
    @apply text-white;
  }
  input[type="text"],
  input[type="email"],
  textarea {
    &.invalid {
      @apply border-red-300 !important;
    }
  }
}
