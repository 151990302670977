@tailwind components;

body[data-slug="thankyou"] {
  nav.main-menu ul li a[href="/contact.html"],
  nav.main-menu ul li a[href="/contact"] {
    &:after {
      width: calc(100% - 4rem);
      @apply left-3;
    }
  }
  nav.main-menu [data-pop-proposal] {
    @apply hidden;
  }
  .hero {
    @apply min-h-min;
    .container {
      @apply pt-48 pb-32;

      h2 {
        @apply text-left;
      }
    }
    &:after {
      content: "Thank You";
    }
  }
}
