.spinner {
  width: 56px !important;
  height: 56px !important;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #15406a 94%, #0000) top/9px 9px
      no-repeat,
    conic-gradient(#0000 30%, #15406a);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
  animation: spinner-blue 1s infinite linear;
}

@keyframes spinner-blue {
  100% {
    transform: rotate(1turn);
  }
}
