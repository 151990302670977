@tailwind components;

body[data-slug="contact"] {
  nav.main-menu ul li a[href="/contact.html"],
  nav.main-menu ul li a[href="/contact"] {
    &:after {
      width: calc(100% - 4rem);
      @apply left-3;
    }
  }
  .hero {
    @apply min-h-min;
    .container {
      @apply pt-48 pb-32;

      h2 {
        @apply text-left;
      }
    }
    &:after {
      content: "Contact Us";
    }
  }
  form.container {
    @apply pt-20 pb-10 flex flex-col items-center justify-center relative max-w-4xl;

    div {
      @apply w-full;
      div {
        @apply w-auto;
      }
    }
    .col-2 {
      @apply grid grid-cols-2 gap-6;
    }
    input,
    textarea {
      @apply outline-0 transition-all border-2 border-tuftsy border-opacity-40 focus:border-opacity-100 shadow-blue-straight-in-sm;
    }
  }

  .request {
    @apply bg-blue-lighter py-20 text-blue;
    .container {
      @apply flex flex-col justify-center items-center;

      h3 {
        @apply font-sans text-4xl uppercase mb-10;
      }
      p {
        @apply text-base mb-12;
      }
    }
  }
}
