@import "base";
@import "components";

@font-face {
  font-family: "cera_pro";
  src: url("https://presentingsolutions.com/sitefiles/cerapro-medium-webfont.woff2")
      format("woff2"),
    url("https://presentingsolutions.com/sitefiles/cerapro-medium-webfont.woff")
      format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "cera_pro";
  src: url("https://presentingsolutions.com/sitefiles/cerapro-black-webfont.woff2")
      format("woff2"),
    url("https://presentingsolutions.com/sitefiles/cerapro-black-webfont.woff")
      format("woff");
  font-weight: 700;
  font-style: normal;
}
