@import "designsystem/ps-designsystem";

@import "features/speech-bubble";
@import "features/hamburgers";

@import "_home";
@import "_services";
@import "_about";
@import "_contact";
@import "_wufoo";
@import "_thankyou";

html,
body {
  min-height: 100vh;
}
body {
  display: flex;
  flex-direction: column;
}
#topic-modal {
  h1,
  h2,
  h3,
  h4,
  h5 {
    @apply mb-4;
  }
  .bullets.columns-2,
  .bullets.columns-4 {
    @apply mx-auto mb-8;
    column-count: 1;
    min-height: 100%;
    li {
      display: block;
    }
  }
  .bullets.columns-2 {
  }
}

#proposal-modal {
  @apply pointer-events-auto overflow-y-auto;
  .modal-dialog {
    padding-bottom: 3rem;

    .modal-content {
      @apply p-0 max-h-max pb-8 rounded-lg;
      iframe {
        height: 116rem;
      }
    }
  }
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after,
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  @apply bg-blue;
}
.footer-form {
  @apply relative bg-blue-diagonal;

  &:before {
    @apply block absolute w-full h-full top-0 left-0 pointer-events-none bg-gradient-to-b from-transparent to-blue z-0;
    content: " ";
  }

  .container {
    @apply text-white py-10 flex flex-col items-center justify-center relative;
    .form-wrapper {
      h6 {
        @apply text-base;
      }
      form {
        @apply mt-10;
      }
    }
  }
}
body > footer {
  @apply relative text-white border-t-8 border-t-tuftsy  bg-blue-diagonal mt-auto;

  &:before {
    @apply block absolute w-full h-full top-0 left-0 pointer-events-none bg-gradient-to-tl from-transparent  to-blue z-0;
    content: " ";
  }

  .container {
    @apply text-white pt-10 pb-5 flex flex-col items-center justify-center relative;

    img {
      @apply block w-40 mb-5;
    }
    ul.cities {
      @apply flex text-2xs mb-6;
      li {
        @apply inline-block px-3 pt-5 pb-2 relative;

        &:before {
          @apply inline-block absolute -ml-3 pointer-events-none;
          content: "•";
        }
        &:first-child {
          &:before {
            content: "";
          }
        }
      }
    }

    a {
      @apply block text-white;
      &[href^="tel"] {
        @apply no-underline;
      }
    }
    p.copyright {
      @apply text-xs mt-6 mb-0;
    }
  }
}
