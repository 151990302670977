@tailwind components;
@tailwind utilities;

@layer components {
  body.loaded.nav-loaded nav.main-menu {
    @apply duration-300;
    transition-property: box-shadow, background, filter;
  }

  nav.main-menu {
    @apply fixed top-0 bg-transparent flex md:grid md:grid-cols-3 md:justify-center items-center text-base w-full z-50  ease-in-out drop-shadow-none;

    &.scrolled {
      @apply bg-white/95 drop-shadow-xl;
    }
    .logo-badge {
      @apply block p-8;

      img {
        @apply h-12 w-auto;
      }
    }
    &.open ul {
      @apply h-80;
    }

    .hamburger {
      @apply block md:hidden;
    }

    ul {
      @apply w-full justify-center absolute md:relative left-0 top-24 md:top-0 bg-white md:bg-transparent shadow-lg md:shadow-none md:flex h-0 md:h-auto overflow-hidden duration-300;
      transition-property: height;

      li {
        a {
          @apply block px-8 pt-8 pb-6 text-blue relative;

          &:before {
            @apply hidden md:inline-block absolute -ml-9 pointer-events-none;
            content: "•";
          }

          &:after {
            @apply absolute h-0.5 bg-blue block bottom-9;
            content: " ";
            transition: all 400ms cubic-bezier(0.67, 0.075, 0.34, 1.65); /* custom */
            width: 0;
            left: 50%;
            margin-left: 1.25rem;
            width: calc(0% - 10rem);
          }
          &:hover:after,
          &.active:after {
            width: calc(100% - 4rem);
            @apply left-3;
          }
        }
        &:first-child {
          a:before {
            content: "";
          }
        }
      }
    }

    .btn {
      @apply text-sm py-3 px-5 mr-5 -mt-3;
    }
  }
}
