.fade {
  transition: opacity 0.15s ease-in-out;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    top: 100%;
  }

  to {
    opacity: 1;
    top: 0;
  }
}

@keyframes fadeInBlur {
  from {
    opacity: 0;
    filter: blur(20px);
  }

  to {
    opacity: 1;
    filter: blur(0px);
  }
}

@keyframes fadeInUpSmall {
  from {
    opacity: 0;
    top: 10%;
  }

  to {
    opacity: 1;
    top: 0;
  }
}

.fadeUp {
  animation-name: fadeInUp;
  animation-duration: 250ms !important;
  transition-duration: 250ms !important;
  animation-iteration-count: 1 !important;
}

.fadeUpSmall {
  animation-name: fadeInUpSmall;
  animation-duration: 350ms !important;
  transition-duration: 350ms !important;
  animation-iteration-count: 1 !important;
}

.fadeInBlur {
  animation-name: fadeInBlur;
  animation-duration: 350ms !important;
  transition-duration: 350ms !important;
  animation-iteration-count: 1 !important;
}
[data-aos^="fade"][data-aos^="fade"] {
  opacity: 0;
  transition-property: opacity, transform, blur !important;
}
