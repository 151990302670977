@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply text-sm rounded-md p-3 px-10 bg-white border border-silver text-blue font-sans tracking-tight text-center;
    transition: all 400ms cubic-bezier(0.67, 0.075, 0.34, 1.65);
    box-shadow: 0px 3px 15px rgba(21, 64, 106, 0.15);

    &:hover {
      transform: scale(1.05);
    }
    &.font-serif {
      @apply pb-2;
    }
    &.btn-sm {
      @apply text-sm py-4 px-8;
    }
    &.btn-lg {
      @apply text-lg py-4 px-8;

      &.btn-unicorn {
        box-shadow: 0px 3px 30px rgba(21, 64, 106, 0.5);
      }
    }

    &.btn-xl {
      @apply text-xl py-8 px-16;

      &.btn-unicorn {
        @apply shadow-blue-lg-straight;
      }
    }

    &.btn-wide {
      @apply px-20;
    }

    &.btn-xwide {
      @apply px-40;
    }

    &.btn-blue {
      @apply border-0 bg-blue text-white;
    }

    &.btn-unicorn {
      @apply border-0 bg-unicorn;
      box-shadow: 0px 3px 15px rgba(21, 64, 106, 0.5);
    }
  }
}
