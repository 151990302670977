.speech-bubble-container {
}
.speech-bubble-arrow {
  margin-left: -20px;
  text-indent: -99999px;
  z-index: 1;
  position: relative;
  overflow: hidden;
  width: calc(100% + 40px);
  height: 0;
  margin-bottom: 20px;
  padding-top: 10%; /* ratio of the background sprite frames */
  background-size: 100% 1500%;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-color: transparent; /* The animation sprite as the viewport background */
  animation-fill-mode: forwards, forwards;
}
