@tailwind components;
@tailwind utilities;

@layer components {
  .well {
    @apply md:p-10 md:py-32 relative z-0 -mt-12; /* -z-10 */
    background-position: center center;
    background-size: cover;

    .container {
      @apply py-12;

      h1,
      h2,
      h3,
      h4 {
        @apply bg-white text-blue text-base md:text-3xl px-4 md:px-16 pt-14 pb-12 mx-auto rounded-xl shadow-blue-xl-straight max-w-5xl;

        line-height: 2;
      }
    }

    & + div,
    & + article,
    & + section {
      @apply shadow-blue-lg-top bg-white rounded-t-xl mt-12 md:-mt-12;
    }
  }
}
