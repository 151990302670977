@tailwind components;
@tailwind utilities;
body[data-slug="/"] {
  &.loaded {
    nav.main-menu.loading {
      @apply opacity-100;
      top: 0;
    }
    .hero {
      .container {
        @apply max-w-max;
        h1,
        h2 {
          @apply opacity-100;
        }
      }
    }
  }
  nav.main-menu.loading {
    @apply opacity-0 transition-all duration-1000;
    transition-delay: 2s;
    top: -20rem;
  }
  .hero {
    @apply rounded-none border-b-2 border-blue bg-gradient-to-tl from-white via-white to-blue-light flex flex-col justify-center md:min-h-screen;
    box-shadow: none;
    .container {
      @apply items-start md:min-h-screen; /* max-w-screen-lg; */

      h1,
      h2 {
        @apply block font-serif normal-case text-left text-4xl md:text-7xl leading-normal opacity-0 transition-opacity duration-500; /* text-4xl;*/
      }
      h1 {
        @apply mb-32 delay-200;
      }
      h2 {
        transition-delay: 1.25s;
      }
    }
  }
  .people-testimonials {
    @apply relative;
    .wave {
      @apply absolute left-0 top-0 w-full h-full bg-wave-motion z-10;
    }
    &:before,
    &:after {
      @apply absolute left-0 top-0 w-full h-full;
      content: " ";
    }
    &:before {
      @apply bg-dots opacity-50 pointer-events-none z-0;
      /* dots */
    }
    &:after {
      /* white */
      @apply bg-gradient-to-br from-white via-white to-transparent z-0;
    }
    .container {
      @apply relative z-20;
      &.logo {
        @apply pt-12 md:pt-24;
      }
      img.logo {
        @apply block mx-auto w-screen md:max-w-lg mb-12 md:mb-24;
      } /* logo */

      blockquote {
        @apply text-blue text-base md:text-3xl leading-loose relative pb-4 flex items-center;
        p {
          @apply p-0 m-0; /*pb-6;*/
        }
        &:before {
          @apply absolute -top-4;
          content: " ";
          background: url("https://presentingsolutions.com/sitefiles/small-double-quotes.png")
            left top no-repeat;
          background-size: contain;
          left: -5rem;
          width: 4rem;
          height: 5rem;
        }
        footer {
          @apply hidden;
        }
      }
    }
    img.speech-line {
      @apply relative block z-20 mx-auto px-32 mt-16;
    }
    .circle-item-list {
      @apply mt-0 pb-20;
    }
  }

  .our-work {
    @apply mt-4 bg-white bg-peek pt-20 rounded-b-xl shadow-blue-lg-light-bottom pb-20 relative z-30;
    a {
      @apply font-bold;
    }
    h3 {
      @apply uppercase text-blue font-sans text-center text-5xl mb-10;
    }
    h4 {
      @apply text-blue text-center text-3xl mb-20 leading-relaxed;
    }
    h5 {
      @apply uppercase text-blue font-sans text-center text-3xl mb-4;
    }
    .work-type {
      @apply md:flex items-center justify-center text-base max-w-4xl mx-auto mb-16;
      a {
        @apply block mx-auto mb-4 md:mb-0 md:ml-0 md:mr-14 w-20 md:w-36;
        img {
          @apply block md:w-full;
        }
      }
      p {
        @apply mb-0;
      }
    }
    h6 {
      @apply text-base text-center mb-16;
    }
    .ctas {
      @apply md:flex items-center justify-between max-w-3xl mx-auto;

      button,
      a {
        @apply mx-auto mb-4 md:mb-0 block md:mx-0 md:inline-block font-normal text-base py-6;
      }
    }
  }

  .well {
    .bgd {
      @apply absolute left-0 top-0 w-full grid grid-cols-5 md:grid-cols-9 gap-0 bg-white opacity-25;
      overflow-x: hidden;
      overflow-y: visible;
      div {
        @apply w-full relative;
      }
      img {
        @apply w-3/4 h-auto mx-auto;
      }
    }
    h1,
    h2,
    h3,
    h4 {
      @apply md:text-3xl max-w-5xl text-center md:px-14 md:pt-24 md:pb-20;
      line-height: 2;
    }
  }

  .company-testimonials {
    @apply shadow-blue-lg-light-top mt-24 md:mt-4 relative;

    .quote {
      @apply absolute block md:-mb-10;
      content: " ";
      background: url("https://presentingsolutions.com/sitefiles/large-double-quotes.png")
        left top no-repeat;
      background-size: contain;
      left: 1rem;
      top: -3rem;
      width: 12rem;
      height: 15rem;
    }
    .container {
      @apply relative;
    }
    .no-scroll {
      overflow-x: hidden;
    }

    .bgd {
      @apply absolute block opacity-10 w-full h-full overflow-hidden top-0 left-0;
      .bgd-before {
        @apply absolute block rounded-full opacity-70;
        content: " ";
        background-repeat: no-repeat;
        background-size: contain;
        width: 70rem;
        height: 70rem;
        top: -10rem;
        right: -20rem;
      }
    }

    blockquote {
      @apply pt-48 text-blue text-base md:text-3xl leading-loose relative;

      footer {
        @apply hidden;
      }
    }
    .circle-item-list {
      @apply mt-10 pb-20 flex relative;

      ul {
        @apply mx-auto grid-cols-3;
        li a {
          @apply px-4 md:scale-50 transition-all relative text-base;
          padding-bottom: 2.5rem;
          img {
            @apply opacity-100;
          }
          strong,
          em {
            @apply hidden tracking-tight md:absolute w-full text-center left-0;
          }
          strong {
            @apply md:bottom-0 text-sm md:text-base;
          }
          em {
            @apply text-xs md:text-sm md:-bottom-1;
          }
          &.active {
            @apply scale-100;
            strong,
            em {
              @apply block;
            }
          }
          &:hover:not(.active) {
            @apply scale-75;
          }
        }
      }
    }
  }
  .footer-form {
    .form-wrapper h6 {
      @apply text-3xl;
    }
  }
  .what-we-do {
    @apply relative bg-wave-balance bg-white pt-32 pb-16 text-blue -mt-10 rounded-b-xl shadow-blue-lg-light-bottom z-20;

    h3 {
      @apply text-center text-5xl mb-12;
    }
    p {
      @apply text-3xl leading-relaxed;
    }

    .services {
      div.service-row {
        div.container {
          @apply md:grid grid-cols-2;

          div {
            @apply px-2 py-4 md:p-8 pb-0 border-t border-b border-r border-r-tuftsy-silver border-l border-tuftsy-silver;
            margin-top: -1px;
            &:nth-child(even) {
              margin-left: -1px;
            }
          }
        }

        &:last-of-type {
          div.container div {
            @apply border-b border-b-tuftsy-silver;
          }
        }

        h6 {
          @apply text-3xl font-sans text-center uppercase mb-4;
          strong {
            @apply text-tuftsy font-normal;
          }
        }
        ol {
          @apply text-base inline-block mb-2;
          li {
            @apply ml-12 md:ml-20 mb-6;

            &:before,
            &:after,
            span:before,
            span:after {
              height: 1.5em;
            }
          }
        }
      }
    }

    .cta {
      @apply pt-16 text-center;
    }
  }

  & > footer {
    /*  @apply border-0 shadow-blue-lg-light-top;*/
  }
}
