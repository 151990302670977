.circle-item-list {
  @apply mt-10;
  > ul {
    @apply grid;
  }
  &.cols-auto {
    > ul {
      @apply grid-flow-col auto-cols-auto;
    }
  }
  &.cols-max {
    > ul {
      @apply grid-flow-col auto-cols-max;
    }
  }
  &.cols-2 {
    > ul {
      @apply grid-cols-2 gap-20;
    }
  }
  &.cols-3 {
    > ul {
      @apply grid-cols-3 gap-4 md:gap-20;
    }
  }
  &.cols-4 {
    > ul {
      @apply grid-cols-4 gap-20;
    }
  }
  &.cols-5 {
    > ul {
      @apply grid-cols-5 gap-20;
    }
  }
  li a {
    @apply flex flex-col items-center no-underline font-sans tracking-tight relative text-sm;
  }
  strong {
    @apply font-normal font-sans block text-center mb-2;
  }
  em {
    @apply font-serif text-xs md:text-base block text-center;
  }
  &.fade-circled {
    li a {
      @apply relative block mb-2;

      img {
        @apply block rounded-full relative max-h-60 mx-auto mb-4;

        &.faded {
          @apply hidden;
        }

        &.full {
          @apply block rounded-full top-0 left-0 opacity-40 transition ease-in-out duration-500;
        }
      }

      strong {
        @apply text-base tracking-tight;
      }
    }
  }
  &.blue-circled {
    li a span {
      @apply relative block mb-2;
      &:before {
        @apply block bg-blue absolute w-full h-full rounded-full top-0 transition ease-in-out duration-500;
        content: "";
      }
      img {
        @apply block rounded-full relative;

        &.faded {
          @apply opacity-80 grayscale mix-blend-luminosity;
        }

        &.full {
          @apply block absolute w-full h-full rounded-full top-0 left-0 opacity-0 transition ease-in-out duration-500;
        }
      }
    }
  }
  &.fade-circled,
  &.blue-circled {
    li a {
      &:hover,
      &.active {
        &:before {
          @apply opacity-0;
        }
        img.full {
          @apply opacity-100;
        }
      }
    }
  }
}
